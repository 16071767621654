package com.pincer.core.model.tree

import com.pincer.core.model.patches.ChatPatch
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class Chat 

(

    @JsName("principalId") var principalId : String,
    @JsName("wording")     var wording     : String,
    @JsName("edited")      var edited      : Boolean = false,
    @JsName("roles")       var roles       : String? = null,

) 

{

    fun asPatch(): ChatPatch = ChatPatch(
        principalId = principalId,
        wording     = wording,
        edited      = edited,
        roles       = roles,
    )
    
    fun applyPatch(patch: ChatPatch) {
        if (patch.principalId != null) principalId = patch.principalId!!
        if (patch.wording     != null) wording     = patch.wording!!
        if (patch.edited      != null) edited      = patch.edited!!
        if (patch.roles       != null) roles       = patch.roles!!
    }

    fun diff(ancestor: Chat) = ChatPatch (
        principalId = if (principalId == ancestor.principalId) null else principalId,
        wording     = if (wording     == ancestor.wording)     null else wording,
        edited      = if (edited      == ancestor.edited)      null else edited,
        roles       = if (roles       == ancestor.roles)       null else roles,
    )

    fun duplicate() = Chat(
        principalId = principalId, 
        wording     = wording, 
        edited      = edited, 
        roles       = roles, 
    )

}
