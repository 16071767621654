package com.pincer.core.model.patches

import com.pincer.core.model.tree.Question
import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class QuestionPatch

(

    @JsName("format")       var format       : String? = null,
    @JsName("code")         var code         : String? = null,
    @JsName("wording")      var wording      : String? = null,
    @JsName("helpText")     var helpText     : String? = null,
    @JsName("options")      var options      : String? = null,
    @JsName("regex")        var regex        : String? = null,
    @JsName("roles")        var roles        : String? = null,

) 

{

    fun asQuestion(): Question {
        val question = Question(
            wording = wording ?: throw InvalidData(),
            code    = code    ?: throw InvalidData(),
        )
        question.applyPatch(this)
        return question
    }

}