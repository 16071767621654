package com.pincer.core.model.patches

import com.pincer.core.model.tree.Chat
import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class ChatPatch

(

    @JsName("principalId") var principalId: String? = null,
    @JsName("wording")     var wording    : String? = null,
    @JsName("edited")      var edited     : Boolean? = null,
    @JsName("roles")       var roles      : String? = null,

) 

{

    fun asChat(): Chat {
        val chat = Chat(
            principalId = principalId ?: throw InvalidData(),
            wording     = wording     ?: throw InvalidData(),
        )
        chat.applyPatch(this)
        return chat
    }
    
}