package com.pincer.app

import kotlinx.coroutines.CoroutineScope
import kotlin.js.JsName
import com.pincer.core.model.tree.Answer
import com.pincer.core.model.tree.Participant
import com.pincer.core.model.tree.QuestionFormat
import com.pincer.core.model.tree.CHATS_SCOPE
import com.pincer.core.model.tree.PERMISSIONS_SCOPE
import com.pincer.core.model.tree.QUESTIONS_SCOPE
import com.pincer.core.model.tree.CANDIDATES_SCOPE
import com.pincer.core.model.tree.SUMMARIES_SCOPE
import com.pincer.core.model.tree.NAME_SCOPE
import com.pincer.core.model.tree.DESCRIPTION_SCOPE
import com.pincer.core.model.tree.RULES_SCOPE
import com.pincer.core.model.tree.PARTICIPANTS_SCOPE
import com.pincer.core.model.tree.PARTICIPANTS_ANSWERS_SCOPE
import com.pincer.core.model.tree.PARTICIPANTS_RULES_SCOPE
import com.pincer.core.model.tree.PARTICIPANTS_ROLES_SCOPE
import com.pincer.core.model.patches.QuestionPatch
import com.pincer.core.model.patches.AnswerPatch
import com.pincer.core.functions.termsNeeded
import com.pincer.core.functions.getAnswer
import com.pincer.core.functions.readablePid
import com.pincer.core.functions.getRolesForParticipant
import com.pincer.core.functions.checkPermission
import com.pincer.core.functions.validateText
import com.pincer.core.functions.validateQuestionCode
import com.pincer.core.functions.validateRegex
import com.pincer.core.functions.validateRoles
import com.pincer.core.functions.validateOptions
import com.pincer.core.functions.validateAnswer
import com.pincer.client.Changes
import com.pincer.client.PincerLoaderStatus
import com.pincer.client.PincerLoaderStatus.FAILED
import com.pincer.core.Logger

class Application (val coroutineScope: CoroutineScope, val log: Logger) {

    @JsName("controller") val controller = Controller(coroutineScope, log)

    init { 
        log.i("============== Pincer App Initializing ==============")
        val model = controller.model
        val locale = model.getLocale()
        log.i("Loading locale : ${locale}")
        I18n.load(locale, log)
        if (model.getDeviceId() == null) {
            log.i("No device id found, calling resetDevice")
            controller.resetDevice()
        } else {
            log.i("Found device id ${model.getDeviceId()}")
            // TODO have a think about this ... having so much of the set up burried in the wrap 
            // meant I needed to add this when I dropped loadParticipations. Bit counter intuitive.
            controller.ensureContract()
        }
    }

    fun stop() {
        log.i("Stopping application")
        controller.model.safelyStopPincerLoaders()
        // TODO: also kill any running controller activity
        log.i("============== Pincer App Stopped ==============")

    }

    @JsName("root") val root = ViewModelNode(key = "root", null)

    @JsName("location")
    fun location() = controller.model.location

    @JsName("changes")
    val changes = Changes(log)

    @JsName("listenToChanges")
    fun listenToChanges(f:() -> Unit) = changes.listen(f)

    private var rebuilding = false

    private var flagRebuild = false

    @JsName("rebuild")
    fun rebuild() {
        if (rebuilding) {
            flagRebuild = true
        }
        else {
            rebuildInner()
        }
    }

    init {
        controller.changes.listen( { rebuild() })
    }

    private fun rebuildInner() {
        rebuilding = true
        var count = 0
        val model = controller.model
        do {
            flagRebuild = false
            count++
            log.i("Rebuild $count")
            root.wording = I18n.translate(root.key)
            root.patrol { 
                if (model.unexpectedError != null) {
                    root.hasChild(key = "problem", sortToken = "010", wording = I18n.translate("unexpectedError") + " : " + model.unexpectedError)
                }
                else if (model.pincerLoadersFailed()) {
                    root.hasChild(key = "problem", sortToken = "020", wording = I18n.translate("backgroundFailed"))
                }
                else if (model.settingsPincerLoader == null || !model.settingsPincerLoader!!.pincerLoaded()) {
                    root.hasChild(key = "loading", sortToken = "030")
                }
                else {
                    val selfId = model.getPrincipalId()
                    val settingsPincer = model.settingsPincerLoader!!.localPincer
                    val settingsParticipant = settingsPincer.participants[selfId]!!
                    val gnAnswer = getAnswer(pincer = settingsPincer, questionIdOrCode = "GIVEN_NAME", participant = settingsParticipant)
                    if (gnAnswer != null && model.givenName == null) model.givenName = gnAnswer.wording
                    val fnAnswer =  getAnswer(pincer = settingsPincer, questionIdOrCode = "FAMILY_NAME", participant = settingsParticipant)
                    if (fnAnswer != null && model.familyName == null) model.familyName = fnAnswer.wording
                    val tcAnswer = getAnswer(pincer = settingsPincer, questionIdOrCode = "TERMS_ACCEPTED_AT", participant = settingsParticipant)
                    val termsNeeded = tcAnswer == null || termsNeeded(lastAcceptedAtMillis = tcAnswer.wording.toLong())
                    if (gnAnswer == null || fnAnswer == null || termsNeeded) {
                        it.hasChild(key = "setUpForm", sortToken = "040").patrol { 
                            it.hasChild("givenName").patrol {
                                it.hasInput(model.givenName ?: "").patrol {
                                    it.hasAction("setGivenName")
                                }
                                it.mightHaveInvalid(validateText(model.givenName))
                            }
                            it.hasChild("familyName").patrol { 
                                it.hasInput(model.familyName ?: "").patrol { 
                                    it.hasAction("setFamilyName")
                                }
                                it.mightHaveInvalid(validateText(model.familyName))
                            }
                            if (termsNeeded) {
                                it.hasChild("termsAndConditions").patrol {  
                                    val pincerCustomerAgreement = I18n.translate("pincerCustomerAgreement")
                                    val link = "[$pincerCustomerAgreement](https://pincer.com/terms)"
                                    it.hasButton(I18n.translate("acceptTerms")).patrol {
                                        it.hasAction("acceptTerms")
                                    }
                                    it.hasHelpText(I18n.translate("termsSummary", link))
                                }
                            }
                        }
                    }
                    else {
                        root.hasChild(key = "summaries", sortToken = "050").patrol {
                            for (pid in settingsPincer.summaries.keys) {
                                val summary = settingsPincer.summaries[pid]!!
                                it.hasChild(key = pid, wording = summary.name).patrol {
                                    it.hasChild("pid").patrol {
                                        it.hasText(pid)
                                    }
                                    if (summary.wording != null) {
                                        // Option here for more modelling of the text. 
                                        // e.g. Label could be name of last chat person
                                        // Or 'description', or 'start time'.
                                        it.hasChild("wording").patrol {
                                            it.hasText(summary.wording!!)
                                        }
                                        // Or we could do different fields and the client could choose.
                                    }
                                    it.hasChild("loadPincer").patrol {
                                        it.hasButton(I18n.translate("loadPincer")).patrol {
                                            it.hasAction("loadPincer").patrol {
                                                it.hasChild(key = "pid", wording = pid)
                                            }
                                        }
                                    }
                                }
                            }
                            it.hasChild(key = "newPincer", sortToken = "zzz").patrol {
                                it.hasChild("newPincerName").patrol {
                                    it.hasInput(model.newPincerName ?: "").patrol {
                                        it.hasAction("setNewPincerName")
                                    }
                                    it.mightHaveInvalid(validateText(model.newPincerName))
                                }
                                it.hasChild("createNewPincer").patrol {
                                    it.hasButton(I18n.translate("createNewPincer")).patrol {
                                        it.hasAction("createNewPincer")
                                    }
                                }
                            }
                        }
                        if (model.controllerActivity.contains("newPincer") || model.controllerActivity.contains("loadPincer")) {
                            it.hasChild(key = "pincer", sortToken = "060").patrol {
                                it.isLoading()
                            }
                        }
                        else if (model.pincerLoader != null && model.pincerLoader!!.pincerLoaded()) {
                            val localPincer = model.pincerLoader!!.localPincer
                            val selfParticipant: Participant? = localPincer.participants[model.getPrincipalId()]
                            val roles = getRolesForParticipant(localPincer, model.getPrincipalId()!!)
                            val readablePid = readablePid(localPincer)
                            it.hasChild(key = "pincer", sortToken = "060", wording = if (localPincer.name == null) readablePid else localPincer.name!!).patrol {
                                it.hasChild("pid").patrol {
                                    it.hasText(readablePid)
                                }
                                if (localPincer.description != null && checkPermission(localPincer, roles, DESCRIPTION_SCOPE, false)) {
                                    it.hasChild("description").patrol {
                                        it.hasText(localPincer.description!!)
                                    }
                                }
                                it.hasChild("shareableLink").patrol {
                                    val link = "https://web.pincer.com/#${localPincer.pid}"
                                    it.hasText(link)
                                }
                                it.hasChild("takePart").patrol {
                                    if (checkPermission(localPincer, roles, CANDIDATES_SCOPE, false)) {
                                        // it.hasChild("times")
                                    }
                                    if (checkPermission(localPincer, roles, CHATS_SCOPE, false)) {
                                        it.hasChild("chats").patrol {
                                            for (chatId in localPincer.chats.keys) {
                                                val chat = localPincer.chats[chatId]!!
                                                val participant = localPincer.participants[chat.principalId]
                                                val name = if (participant != null && participant.name != null) participant.name!! else chat.principalId
                                                it.hasChild(key = chatId, wording = name, sortToken = chatId).patrol { 
                                                    it.hasText(chat.wording)
                                                }
                                            }
                                            it.hasChild("addChatMessage").patrol {
                                                it.hasChild(key = "draftChat").patrol { 
                                                    it.hasInput(model.draftChat ?: "").patrol {
                                                        it.hasAction("setDraftChat")
                                                    }
                                                    it.mightHaveInvalid(validateText(model.draftChat))
                                                }
                                                it.hasChild(key = "addChat").patrol { 
                                                    it.hasButton(I18n.translate("addChat")).patrol {
                                                        it.hasAction("addChat")
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if (checkPermission(localPincer, roles, PARTICIPANTS_SCOPE, false)) {
                                    it.hasChild("participants").patrol {
                                        for (principalId in localPincer.participants.keys) {
                                            val participant = localPincer.participants[principalId]!!
                                            val participantNode = it.hasChild(principalId)
                                            if (participant.name == null) {
                                                participantNode.wording = principalId
                                            }
                                            else {
                                                participantNode.wording = participant.name!!
                                            }
                                            participantNode.patrol { 
                                                if (checkPermission(localPincer, roles, PARTICIPANTS_RULES_SCOPE, false)) {
                                                    it.hasChild(key = "rules").patrol {
                                                        it.hasText(participant.rules)
                                                    }
                                                }
                                                if (checkPermission(localPincer, roles, PARTICIPANTS_ROLES_SCOPE, false)) {
                                                    it.hasChild(key = "roles").patrol {
                                                        it.hasText(participant.roles)
                                                    }
                                                }
                                                if (participant.answers.size > 0 && checkPermission(localPincer, roles, PARTICIPANTS_ANSWERS_SCOPE, false)) {
                                                    it.hasChild(key = "answers").patrol {
                                                        for (answerId in participant.answers.keys) {
                                                            val answer = participant.answers[answerId]!!
                                                            val question = localPincer.questions[answerId]
                                                            val wording: String = if (question != null) question.wording else answerId
                                                            it.hasChild(key = answerId, wording = wording).patrol {
                                                                it.hasText(answer.wording)
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                /*
                                if (checkPermission(localPincer, roles, PERMISSIONS_SCOPE, false)) {
                                    val permissionsNode = pincerNodeChild("permissions")
                                    permissionsNode.patrol { permissionsNodeChild ->
                                        for (permissionId in localPincer.permissions.keys) {
                                                val permission = localPincer.permissions[permissionId]!!
                                                val permissionNode = permissionsNodeChild(permissionId)
                                                permissionNode.wording = permission.role
                                                permissionNode.patrol { permissionNodeChild ->
                                                val scope = permissionNodeChild("scope")
                                                scope.type = "info"
                                                scope.wording = permission.scope
                                                val write = permissionNodeChild("write")
                                                write.type = "info"
                                                write.wording = permission.write.toString()
                                            }
                                        }
                                    }
                                }
                                */
                                if (checkPermission(localPincer, roles, CANDIDATES_SCOPE, false)) {
                                    it.hasChild("candidates").patrol {
                                        if (localPincer.candidatesStale) {
                                            it.isLoading()
                                        }
                                        for (candidateId in localPincer.candidates.keys) {
                                            val candidate = localPincer.candidates[candidateId]!!
                                            var names = "" // dunno why StringBuffer wouldn't work here
                                            for (principalId in candidate.participants.split(",")) {
                                                val participant = localPincer.participants[principalId]
                                                if (names.length > 0) {
                                                    names = names + ", "
                                                }
                                                if (participant == null) {
                                                    names = names + principalId
                                                } 
                                                else {
                                                    names = names + participant.name
                                                }
                                            }
                                            it.hasChild(key = candidateId, wording = candidate.start + " (" + candidate.duration + " mins) " + names)
                                        }
                                    }
                                }
                                if (localPincer.questions.size > 0 && selfParticipant != null && checkPermission(localPincer, roles, QUESTIONS_SCOPE, false)) {
                                    it.hasChild("questions").patrol {
                                        val answers = model.pincerLoader!!.uiPincer.participants!![selfId]!!.answers!!
                                        for (questionId in localPincer.questions.keys) {
                                            val question = localPincer.questions[questionId]!!
                                            val answer: Answer? = selfParticipant.answers[questionId]
                                            val uiAnswer: AnswerPatch? = answers[questionId]
                                            it.hasChild(key = questionId, wording = question.wording).patrol {
                                                if (question.formatLikeSimple()) {
                                                    val textInBox = uiAnswer?.wording ?: answer?.wording
                                                    it.hasInput(textInBox ?: "").patrol {
                                                        it.hasAction("setAnswer").patrol {
                                                            it.hasChild(key = "questionId", wording = questionId)
                                                        }
                                                    }
                                                    it.mightHaveInvalid(validateAnswer(question, textInBox))
                                                } 
                                                if (question.format == QuestionFormat.MULTILINE.toString()) {
                                                    val textInBox = uiAnswer?.wording ?: answer?.wording
                                                    it.hasMultiline(textInBox ?: "").patrol {
                                                        it.hasAction("setAnswer").patrol {
                                                            it.hasChild(key = "questionId", wording = questionId)
                                                        }
                                                    }
                                                    it.mightHaveInvalid(validateAnswer(question, textInBox))
                                                } 
                                                else if (question.format == QuestionFormat.SELECT.toString()) {
                                                    it.hasSelect(answer?.wording ?: "").patrol {
                                                        it.hasChild("options").patrol {
                                                            if (question.options != null) {
                                                                for (line in question.options!!.split("\n")) {
                                                                    it.hasChild(key = "option${line.hashCode()}", wording = line)
                                                                }
                                                            }
                                                        }
                                                        it.hasAction("setAnswer").patrol {
                                                            it.hasChild(key = "questionId", wording = questionId)
                                                        }
                                                    }
                                                }
                                                else if (question.format == QuestionFormat.CHECKBOX.toString()) {
                                                    val trueOrFalse = if (answer != null && answer.wording == "true") "true" else "false"
                                                    it.hasCheckbox(trueOrFalse).patrol {
                                                        it.hasAction("setAnswer").patrol {
                                                            it.hasChild(key = "questionId", wording = questionId)
                                                        }
                                                    }
                                                }
                                                if (question.hasHelpText()) {
                                                    it.hasHelpText(question.helpText!!)
                                                }
                                            }
                                        }
                                    }
                                }
                                it.hasChild("settings").patrol {
                                    if (checkPermission(localPincer, roles, NAME_SCOPE, true)) {
                                        it.hasChild("pincerName").patrol { 
                                            val textInBox = model.pincerLoader!!.uiPincer.name ?: localPincer.name
                                            it.hasInput(textInBox ?: "").patrol {
                                                it.hasAction("setPincerName")
                                            }
                                            it.mightHaveInvalid(validateText(textInBox))
                                        }
                                    }
                                    if (checkPermission(localPincer, roles, DESCRIPTION_SCOPE, true)) {
                                        it.hasChild("pincerDescription").patrol {
                                            val textInBox = model.pincerLoader!!.uiPincer.description ?: localPincer.description
                                            it.hasInput(textInBox ?: "").patrol {
                                                it.hasAction("setPincerDescription")
                                            }
                                            it.mightHaveInvalid(validateText(textInBox))
                                        }
                                    }
                                    if (checkPermission(localPincer, roles, RULES_SCOPE, true)) {
                                        it.hasChild("pincerRules").patrol { 
                                            it.hasInput(localPincer.rules).patrol {
                                                it.hasAction("setPincerRules")
                                            }
                                        }
                                    }
                                    if (checkPermission(localPincer, roles, QUESTIONS_SCOPE, true)) {
                                        it.hasChild("questions").patrol {
                                            for (questionId in localPincer.questions.keys) {
                                                val question = localPincer.questions[questionId]!!
                                                if (model.pincerLoader!!.uiPincer.questions!!.get(questionId) == null) {
                                                    model.pincerLoader!!.uiPincer.questions!!.set(questionId, QuestionPatch())
                                                }
                                                val uiQuestion = model.pincerLoader!!.uiPincer.questions!!.get(questionId)!!
                                                it.hasChild(key = questionId, wording = question.code).patrol {
                                                    it.hasChild(key = "code", wording = I18n.translate("question-code")).patrol {
                                                        val textInBox = uiQuestion.code ?: question.code
                                                        it.hasInput(textInBox).patrol { 
                                                            it.hasAction("setQuestionCode").patrol {
                                                                it.hasChild(key = "questionId", wording = questionId)
                                                            }
                                                        }
                                                        it.mightHaveInvalid(validateQuestionCode(textInBox))
                                                        it.hasHelpText(I18n.translate("question-code-help"))
                                                    }
                                                    it.hasChild(key = "wording", wording = I18n.translate("question-wording")).patrol {  
                                                        val textInBox = uiQuestion.wording ?: question.wording
                                                        it.hasInput(textInBox).patrol {
                                                            it.hasAction("setQuestionWording").patrol {
                                                                it.hasChild(key = "questionId", wording = questionId)
                                                            }
                                                        }
                                                        it.mightHaveInvalid(validateText(textInBox))
                                                    }
                                                    it.hasChild(key = "format", wording = I18n.translate("question-format")).patrol {  
                                                        it.hasSelect(question.format).patrol {
                                                            it.hasChild("options").patrol {
                                                                val options = it
                                                                QuestionFormat.values().forEach {
                                                                    if (it == QuestionFormat.TIMESTAMP && question.format != "TIMESTAMP") {
                                                                        // skip
                                                                    }
                                                                    else {
                                                                        options.hasChild(key = it.toString())
                                                                    }
                                                                }
                                                            }
                                                            it.hasAction("setQuestionFormat").patrol {
                                                                it.hasChild(key = "questionId", wording = questionId)
                                                            }
                                                        }
                                                        // no invalidation check here ... we just have a gray box
                                                    }                                                    
                                                    it.hasChild(key = "helpText", wording = I18n.translate("question-help-text")).patrol { 
                                                        val textInBox = uiQuestion.helpText ?: question.helpText 
                                                        it.hasInput(textInBox ?: "").patrol {
                                                            it.hasAction("setQuestionHelpText").patrol {
                                                                it.hasChild(key = "questionId", wording = questionId)
                                                            }
                                                        }
                                                        it.mightHaveInvalid(validateText(textInBox))
                                                    }
                                                    if (question.format == QuestionFormat.SELECT.toString()) {
                                                        it.hasChild(key = "options", wording = I18n.translate("question-options")).patrol {
                                                            val textInBox = uiQuestion.options ?: question.options 
                                                            it.hasMultiline(textInBox ?: "").patrol {
                                                                it.hasAction("setQuestionOptions").patrol {
                                                                    it.hasChild(key = "questionId", wording = questionId)
                                                                }
                                                            }
                                                            it.mightHaveInvalid(validateOptions(textInBox))
                                                            it.hasHelpText(I18n.translate("question-options-help"))
                                                        }
                                                    }
                                                    if (question.format == QuestionFormat.REGEX.toString()) {
                                                        it.hasChild(key = "regex", wording = I18n.translate("question-regex")).patrol {
                                                            val textInBox = uiQuestion.regex ?: question.regex 
                                                            it.hasInput(textInBox ?: "").patrol {
                                                                it.hasAction("setQuestionRegex").patrol {
                                                                    it.hasChild(key = "questionId", wording = questionId)
                                                                }
                                                            }
                                                            it.mightHaveInvalid(validateRegex(textInBox))
                                                            it.hasHelpText(I18n.translate("question-regex-help"))
                                                        }
                                                    }
                                                    it.hasChild(key = "roles", wording = I18n.translate("question-roles")).patrol {
                                                        val textInBox = uiQuestion.roles ?: question.roles
                                                        it.hasInput(textInBox ?: "").patrol {
                                                            it.hasAction("setQuestionRoles").patrol {
                                                                it.hasChild(key = "questionId", wording = questionId)
                                                            }
                                                        }
                                                        it.mightHaveInvalid(validateRoles(textInBox))
                                                        it.hasHelpText(I18n.translate("question-roles-help"))
                                                    }
                                                }
                                            }
                                            val newQuestion = model.pincerLoader!!.uiPincer.questions!!.get("NEW")!!
                                            it.hasChild("newQuestion").patrol {
                                                it.hasChild(key = "newQuestionCode", wording = I18n.translate("question-code")).patrol {
                                                    it.hasInput(newQuestion.code ?: "").patrol {
                                                        it.hasAction("setNewQuestionCode")
                                                    }
                                                    it.mightHaveInvalid(validateQuestionCode(newQuestion.code))
                                                    it.hasHelpText(I18n.translate("question-code-help"))
                                                }
                                                it.hasChild(key = "newQuestionWording", wording = I18n.translate("question-wording")).patrol {
                                                    it.hasInput(newQuestion.wording ?: "").patrol {
                                                        it.hasAction("setNewQuestionWording")
                                                    }
                                                    it.mightHaveInvalid(validateText(newQuestion.wording))
                                                }
                                                it.hasChild(key = "newQuestionFormat", wording = I18n.translate("question-format")).patrol {
                                                    it.hasSelect(newQuestion.format!!).patrol {
                                                        it.hasChild("options").patrol {
                                                            val options = it
                                                            QuestionFormat.values().forEach {
                                                                if (it == QuestionFormat.TIMESTAMP) {
                                                                    // skip
                                                                }
                                                                else {
                                                                    options.hasChild(key = it.toString())
                                                                }
                                                            }
                                                        }
                                                        it.hasAction("setNewQuestionFormat")
                                                    }
                                                }
                                                if (newQuestion.format == QuestionFormat.SELECT.toString()) {
                                                    it.hasChild(key = "newQuestionOptions", wording = I18n.translate("question-options")).patrol {
                                                        it.hasMultiline(newQuestion.options ?: "").patrol {
                                                            it.hasAction("setNewQuestionOptions")
                                                        }
                                                        it.mightHaveInvalid(validateOptions(newQuestion.options))
                                                        it.hasHelpText(I18n.translate("question-options-help"))
                                                    }   
                                                }
                                                if (newQuestion.format == QuestionFormat.REGEX.toString()) {
                                                    it.hasChild(key = "newQuestionRegex", wording = I18n.translate("question-regex")).patrol {
                                                        it.hasInput(newQuestion.regex ?: "").patrol {
                                                            it.hasAction("setNewQuestionRegex")
                                                        }
                                                        it.mightHaveInvalid(validateRegex(newQuestion.regex))
                                                        it.hasHelpText(I18n.translate("question-regex-help"))
                                                    }   
                                                }
                                                it.hasChild(key = "newQuestionHelpText", wording = I18n.translate("question-help-text")).patrol {
                                                    it.hasInput(newQuestion.helpText ?: "").patrol {
                                                        it.hasAction("setNewQuestionHelpText")
                                                    }
                                                    it.mightHaveInvalid(validateText(newQuestion.helpText))
                                                }
                                                it.hasChild(key = "newQuestionRoles", wording = I18n.translate("question-roles")).patrol {
                                                    it.hasInput(newQuestion.roles ?: "").patrol {
                                                        it.hasAction("setNewQuestionRoles")
                                                    }
                                                    it.mightHaveInvalid(validateRoles(newQuestion.roles))
                                                    it.hasHelpText(I18n.translate("question-roles-help"))
                                                }
                                                it.hasChild("addQuestion").patrol {
                                                    it.hasButton(I18n.translate("addQuestion")).patrol {
                                                        it.hasAction("addQuestion")
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (selfParticipant != null) {
                                        it.hasChild("participantRules").patrol { 
                                            it.hasInput(selfParticipant.rules).patrol {
                                                it.hasAction("setParticipantRules")
                                            }
                                        }
                                    }
                                }
                                if (selfParticipant == null) {
                                    // could check for a comma here and build select box if there's a choice
                                    // but it would be heading to a bespoke UI anyways
                                    // assume one role for now ...
                                    it.hasChild("participateAssumeOneOpenRole").patrol {
                                        it.hasButton("Participate").patrol {
                                            it.hasAction("participateAssumeOneOpenRole")
                                        }
                                    }
                                } 
                            }
                        }
                    }
                }
                it.hasChild(key = "settingsForm", sortToken = "070").patrol {
                    /*
                    it.hasChild("name").patrol { 
                        it.hasInput(key = "setName", wording = model.settingsPincerLoader!!.localPincer.name ?: "")
                    }
                    */
                    it.hasChild("locale").patrol {
                        it.hasSelect(model.getLocale()).patrol {
                            it.hasChild("options").patrol {
                                it.hasChild("en")
                                it.hasChild("es")
                                it.hasChild("fr")
                            }
                            it.hasAction("setLocale")
                        }
                    }
                    it.hasChild("advancedOptions").patrol {
                        it.hasCheckbox(model.showAdvancedOptions().toString()).patrol {
                            it.hasAction("toggleAdvancedOptions")
                        }
                        it.hasHelpText(I18n.translate("showAdvancedOptions"))
                    }
                    if (model.showAdvancedOptions()) {
                        it.hasChild("serverUrl").patrol {
                            it.hasInput(model.getServerUrl()).patrol {
                                it.hasAction("setServerUrl")
                            }
                        }
                        it.hasChild("resetDevice").patrol {
                            it.hasButton(I18n.translate("resetDevice")).patrol {
                                it.hasAction("resetDevice")
                            }
                        }
                    }
                }
            }
        } while (flagRebuild && count < 100)
        rebuilding = false
        log.i("Complete, firing listener")
        changes.emit()
    }

}
