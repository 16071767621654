package com.pincer.core.model.tree

import com.pincer.core.model.patches.CandidatePatch
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class Candidate 

(

    @JsName("start")        var start        : String,
    @JsName("duration")     var duration     : Int,
    @JsName("participants") var participants : String,

) 

{

    fun asPatch() = CandidatePatch(
        start         = start,
        duration      = duration,
        participants  = participants,
    )

    fun applyPatch(patch: CandidatePatch) {
        if (patch.start         != null) start         = patch.start!!
        if (patch.duration      != null) duration      = patch.duration!!
        if (patch.participants  != null) participants  = patch.participants!!
    }

    fun diff(parent: Candidate) =
        CandidatePatch (
            start         = if (start         == parent.start)         null else start,
            duration      = if (duration      == parent.duration)      null else duration,
            participants  = if (participants  == parent.participants)  null else participants,
        )

    // TODO one in a million chance of collisions here, but that's too high. MD4?
    // Or perhaps that idea where they get grouped in collections by date and duration
    fun id() = "C${hashCode().toLong()+Int.MAX_VALUE}"

    fun duplicate(): Candidate = Candidate(start = start, duration = duration, participants = participants)

}
