package com.pincer.core.model.patches

import com.pincer.core.model.tree.Permission
import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class PermissionPatch

(

    @JsName("role")        var role        : String? = null,
    @JsName("scope")       var scope       : String? = null,
    @JsName("write")       var write       : Boolean? = null,

) 

{

    fun asPermission(): Permission {
        val permission = Permission(
            role        = role        ?: throw InvalidData(),
            scope       = scope       ?: throw InvalidData(),
            write       = write       ?: throw InvalidData(),
        )
        permission.applyPatch(this)
        return permission
    }

}