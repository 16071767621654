package com.pincer.core.model.patches

import com.pincer.core.model.tree.Summary
import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class SummaryPatch

(

    @JsName("name")       var name       : String? = null,
    @JsName("wording")    var wording    : String? = null,
    @JsName("sid")        var sid        : Long? = null,
    @JsName("seenStatus") var seenStatus : Int? = null,
    @JsName("dotNumber")  var dotNumber  : Int? = null,

) 

{

    fun asSummary(): Summary {
        val summary = Summary(
            name       = name       ?: throw InvalidData(),
            sid        = sid        ?: throw InvalidData(),
            seenStatus = seenStatus ?: throw InvalidData(),
            dotNumber  = dotNumber  ?: throw InvalidData(),
        )
        summary.applyPatch(this)
        return summary
    }
    
}