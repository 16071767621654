package com.pincer.app

import com.pincer.core.model.tree.Answer
import com.pincer.core.model.tree.Question
import com.pincer.core.model.tree.QuestionFormat
import com.pincer.core.model.tree.Chat
import com.pincer.core.model.patches.PincerPatch
import com.pincer.core.model.patches.QuestionPatch
import com.pincer.core.model.Device
import com.pincer.core.model.tree.Participant
import com.pincer.core.functions.upsertAnswer
import com.pincer.core.functions.newQuestionId
import com.pincer.core.functions.newChatId
import com.pincer.core.functions.validateText
import com.pincer.core.functions.validateRoles
import com.pincer.core.functions.validateId
import com.pincer.core.functions.validateLocale
import com.pincer.core.functions.validateOptions
import com.pincer.core.functions.validateQuestion
import com.pincer.core.functions.validateQuestionCode
import com.pincer.core.TEMPLATE_PID
import com.pincer.core.PincerException
import com.pincer.client.ClientSession
import com.pincer.client.PincerLoader
import com.pincer.client.Changes
import kotlin.js.JsName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.delay
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.datetime.Clock
import com.pincer.core.Logger

class Controller (val coroutineScope: CoroutineScope, val log: Logger) {

    var contract: ClientSession? = null

    var model = Model()

    val changes = Changes(log)

    private val mutex = Mutex()

    private fun checkLoaded() {
        if (model.pincerLoader == null || !model.pincerLoader!!.pincerLoaded()) {
            throw PincerException("Pincer not loaded yet")
        }
    }

    private fun checkSettingsLoaded() {
        if (model.settingsPincerLoader == null || !model.settingsPincerLoader!!.pincerLoaded()) {
            throw PincerException("Settings pincer not loaded yet")
        }
    }

    private fun wrap(id: String, f:suspend () -> Unit) {
        if (model.controllerActivity.contains(id)) {
            log.w("Ignoring repeated action $id")
        }
        else {
            model.controllerActivity.add(id)
            coroutineScope.launch {
                mutex.withLock {
                    try {
                        if (contract == null) {
                            log.i("Setting up contract")
                            if (model.getDeviceId() == null) {
                                log.i("No device id, requesting a new one")
                                contract = ClientSession.newDevice(model.getServerUrl(), log)
                                model.setDeviceId(contract!!.deviceId)
                                model.setDeviceSecret(contract!!.deviceSecret)
                                model.setPrincipalId(contract!!.principalId)
                                log.i("Contract set up for new device id ${model.getDeviceId()}")
                            } 
                            else {
                                contract = ClientSession(
                                            server = model.getServerUrl(), 
                                            deviceId = model.getDeviceId()!!, 
                                            deviceSecret = model.getDeviceSecret()!!, 
                                            principalId = model.getPrincipalId()!!,
                                        )
                                log.i("Contract set up for extant device id ${model.getDeviceId()}")
                            }
                            log.i("Initalizing settings pincerLoader")
                            model.settingsPincerLoader = PincerLoader(
                                contract = contract!!, 
                                pid = model.getPrincipalId()!!.substring(4), 
                                coroutineScope = coroutineScope,
                                log = log,
                            )
                            model.settingsPincerLoader!!.changes.listen({ changes.emit() })
                            model.settingsPincerLoader!!.start()
                        }
                        f()
                    } 
                    /*       
                    catch (cre: ClientRequestException) {
                        if (model.serverUrlIsDefault()) throw cre
                        model.serverUrlProblem = "bad_server"
                    }
                    */
                    catch (throwable: Throwable) {
                        // TODO - escalate fast and high 
                        log.e("Unexpected throwable: ${throwable.message}")
                        log.i(throwable.stackTraceToString())
                        model.unexpectedError = throwable
                    }
                }
                model.controllerActivity.remove(id)
                changes.emit()
            }
            // Would fire without waiting for the coroutine to complete ...
            // changes.emit()
            // ... would be needed for progress UI as actions completed.
        }
    }

    @JsName("setLocale")
    fun setLocale(locale: String) = wrap("setLocale", {
        if (validateLocale(locale) != null) {
            log.w("Ignoring call to setLocale as invalid : $locale")
        }
        else if (locale != model.getLocale()) {
            model.setLocale(locale)
            I18n.load(locale, log)
        }
    })

    private fun resetInner() {
        model.reset()
        model = Model()
        contract = null
    }
    
    @JsName("resetDevice") 
    fun resetDevice() {
        val keepServerUrl = model.getServerUrl()
        resetInner()
        model.setServerUrl(keepServerUrl)
        wrap("resetDevice", {})
    }

    @JsName("setServerUrl")
    fun setServerUrl(serverUrl: String) {
        resetInner()
        model.setServerUrl(serverUrl)
        wrap("setServerUrl", {})
    }

    @JsName("toggleAdvancedOptions")
    fun toggleAdvancedOptions() = wrap("toggleAdvancedOptions") {
        if (model.showAdvancedOptions()) {
            model.setAdvancedOptions("hide")
        }
        else {
            model.setAdvancedOptions("show")
        }
    }
    
    @JsName("ensureContract") 
    fun ensureContract() {
        // no action needed here, besides the wrap itself
        wrap("ensureContract", {})
    }

    @JsName("setNewPincerName")
    fun setNewPincerName(name: String) = wrap("setNewPincerName", {
        model.newPincerName = name
    })

    private suspend fun loadPincerInner(pid: String,) {
        val pincerLoader = model.pincerLoader
        if (pincerLoader != null) {
            if (pincerLoader.pincerLoaded() && pincerLoader.pid == pid) {
                log.i("Pincer already loaded : $pid")
                return
            }
            log.i("Stopping existing pincerLoader, ${pincerLoader.pid}")
            pincerLoader.stop()
        }
        log.i("Creating new pincerLoader")
        model.pincerLoader = PincerLoader(
            contract = contract!!, 
            pid = pid, 
            coroutineScope = coroutineScope,
            log = log,
        )
        log.i("Registering for change notifications")
        model.pincerLoader!!.changes.listen({ changes.emit() })
        log.i("Starting new pincerLoader")
        model.pincerLoader!!.start()
    }

    @JsName("createNewPincer")
    fun createNewPincer() = wrap("createNewPincer", {
        if (validateText(model.newPincerName) != null) {
            log.w("Ignoring call to creatNewPincer as name is invalid : ${validateText(model.newPincerName)}")
        }
        else {
            val pid = contract!!.fork(pid = TEMPLATE_PID, patch = PincerPatch(name = model.newPincerName))
            log.i("New pincer created : $pid")
            model.newPincerName = null
            loadPincerInner(pid)
        }
    })

    @JsName("loadPincer")
    fun loadPincer(pid: String) = wrap("loadPincer", {
        model.location = pid.filter({ it -> it.isLetter() })
        loadPincerInner(pid)
    })

    @JsName("setDraftChat")
    fun setDraftChat(wording: String) = wrap("setDraftChat", {
        if (wording.trim() == "") {
            // unlike some other fields, we don't mind an empty box
            model.draftChat = null 
        } else {
            model.draftChat = wording
        }
    })

    @JsName("addChat")
    fun addChat() = wrap("addChat", {
        if (model.draftChat == null || model.draftChat!!.trim() == "") {
            model.draftChat = null
            log.w("Ignoring call to addChat as wording is blank")
        } 
        else if (validateText(model.draftChat) != null) {
            log.w("Ignoring call to addChat as wording is invalid : ${validateText(model.draftChat)}")
        }
        else {
            checkLoaded()
            val pincer = model.pincerLoader!!.localPincer
            pincer.chats[newChatId(pincer)] = Chat(principalId = model.getPrincipalId()!!, wording = model.draftChat!!.trim())
            model.draftChat = null
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("addParticipant")
    fun addParticipant(principalId: String, roles: String) = wrap("addParticipant", {
        if (validateRoles(roles) != null) {
            log.w("Ignoring call to addParticipant as roles invalid : $roles")
        }
        if (validateId(principalId) != null) {
            log.w("Ignoring call to addParticipant as principalId invalid : $principalId")
        }
        else {
            checkLoaded()
            val pincer = model.pincerLoader!!.localPincer
            if (pincer.participants[principalId] != null) {
                log.w("Call to add participant already in place $principalId")
                throw PincerException("That participant is already in place.")
            }
            log.i("Creating new participant $principalId with roles $roles")
            pincer.participants[principalId] = Participant(roles = roles)
            log.i("Reporting local change")
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("participateAssumeOneOpenRole")
    fun participateAssumeOneOpenRole() = addParticipant(model.getPrincipalId()!!, model.pincerLoader!!.localPincer.openRoles)

    @JsName("setParticipantRules")
    fun setParticipantRules(rules: String) = wrap("setParticipantRules", {
        checkLoaded()
        model.pincerLoader!!.localPincer.participants[model.getPrincipalId()!!]!!.rules = rules
        model.pincerLoader!!.reportChangeOnLocalPincer()
    })

    @JsName("setPincerRules")
    fun setPincerRules(rules: String) = wrap("setPincerRules", {
        checkLoaded()
        model.pincerLoader!!.localPincer.rules = rules
        model.pincerLoader!!.reportChangeOnLocalPincer()
    })

    @JsName("setPincerName")
    fun setPincerName(name: String) = wrap("setPincerName", {
        if (validateText(name) != null) {
            model.pincerLoader!!.uiPincer.name = name
            log.w("Ignoring call to setPincerName as invalid : ${validateText(name)}")
        }
        else {
            checkLoaded()
            model.pincerLoader!!.uiPincer.name = null
            model.pincerLoader!!.localPincer.name = name
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setPincerDescription")
    fun setPincerDescription(description: String) = wrap("setPincerDescription", {
        if (validateText(description) != null) {
            model.pincerLoader!!.uiPincer.description = description
            log.w("Ignoring call to setPincerDescription as invalid : ${validateText(description)}")
        }
        else {
            checkLoaded()
            model.pincerLoader!!.uiPincer.description = null
            model.pincerLoader!!.localPincer.description = description
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    private fun newQuestion(): QuestionPatch = model.pincerLoader!!.uiPincer.questions!!["NEW"]!!

    @JsName("setNewQuestionCode")
    fun setNewQuestionCode(code: String) = wrap("setNewQuestionCode", {
        newQuestion().code = code
    })

    @JsName("setNewQuestionFormat")
    fun setNewQuestionFormat(format: String) = wrap("setNewQuestionFormat", {
        newQuestion().format = format
    })

    @JsName("setNewQuestionWording")
    fun setNewQuestionWording(wording: String) = wrap("setNewQuestionWording", {
        newQuestion().wording = wording
    })

    @JsName("setNewQuestionHelpText")
    fun setNewQuestionHelpText(helpText: String) = wrap("setNewQuestionHelpText", {
        newQuestion().helpText = helpText
    })

    @JsName("setNewQuestionOptions")
    fun setNewQuestionOptions(options: String) = wrap("setNewQuestionOptions", {
        newQuestion().options = options.trim()
    })

    @JsName("setNewQuestionRegex")
    fun setNewQuestionRegex(regex: String) = wrap("setNewQuestionRegex", {
        newQuestion().regex = regex
    })

    @JsName("setNewQuestionRoles")
    fun setNewQuestionRoles(roles: String) = wrap("setNewQuestionRoles", {
        newQuestion().roles = roles
    })

    @JsName("addQuestion")
    fun addQuestion() = wrap("addQuestion", {
        val questionPatch = newQuestion()
        // For validation up to this point, null values in required fields mean nothing entereded there yet.
        // But from here, we need to trigger validation problems for them.
        // This technique means we don't complain about empty boxes too soon in the UI.
        if (questionPatch.code == null) questionPatch.code = ""
        if (questionPatch.wording == null) questionPatch.wording = ""
        if (questionPatch.format == QuestionFormat.SELECT.toString() && questionPatch.options == null) questionPatch.options = ""
        if (questionPatch.format == QuestionFormat.REGEX.toString() && questionPatch.regex == null) questionPatch.regex = ""
        val question = questionPatch.asQuestion() 
        val problems = validateQuestion(question).problems
        if (!problems.isEmpty()) {
            log.w("Ignoring call to add question as something is invalid...")
            for (problem in problems) log.w(problem)
        }
        else {
            checkLoaded()
            val questionId = newQuestionId(model.pincerLoader!!.localPincer)
            model.pincerLoader!!.localPincer.questions[questionId] = question
            model.pincerLoader!!.reportChangeOnLocalPincer()
            model.pincerLoader!!.uiPincer.questions!!["NEW"] = QuestionPatch(format = "SIMPLE")
        }
    })

    private fun getUiQuestion(questionId: String): QuestionPatch {        
        if (model.pincerLoader!!.uiPincer.questions!!.get(questionId) == null) {
            model.pincerLoader!!.uiPincer.questions!!.set(questionId, QuestionPatch())
        }
        return model.pincerLoader!!.uiPincer.questions!!.get(questionId)!!
    }

    @JsName("setQuestionCode")
    fun setQuestionCode(questionId: String, code: String) = wrap("setQuestionCode", {
        val uiQuestion = getUiQuestion(questionId)
        if (validateQuestionCode(code) != null) {
            uiQuestion.code = code
            log.w("Ignoring call to setQuestionCode as invalid : $code")
        }
        else {
            uiQuestion.code = null
            model.pincerLoader!!.localPincer.questions[questionId]!!.code = code
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setQuestionWording")
    fun setQuestionWording(questionId: String, wording: String) = wrap("setQuestionWording", {
        val uiQuestion = getUiQuestion(questionId)
        if (validateText(wording) != null) {
            uiQuestion.wording = wording
            log.w("Ignoring call to setQuestionWording as invalid : $wording")
        }
        else {
            uiQuestion.wording = null
            model.pincerLoader!!.localPincer.questions[questionId]!!.wording = wording
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setQuestionFormat")
    fun setQuestionFormat(questionId: String, format: String) = wrap("setQuestionFormat", {
        model.pincerLoader!!.localPincer.questions[questionId]!!.format = format
        model.pincerLoader!!.reportChangeOnLocalPincer()
    })

    @JsName("setQuestionHelpText")
    fun setQuestionHelpText(questionId: String, helpText: String) = wrap("setQuestionHelpText", {
        val uiQuestion = getUiQuestion(questionId)
        if (validateText(helpText) != null) {
            uiQuestion.helpText = helpText
            log.w("Ignoring call to setQuestionHelpText as invalid : $helpText")
        }
        else {
            uiQuestion.helpText = null
            model.pincerLoader!!.localPincer.questions[questionId]!!.helpText = helpText
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setQuestionOptions")
    fun setQuestionOptions(questionId: String, options: String) = wrap("setQuestionOptions", {
        val uiQuestion = getUiQuestion(questionId)
        if (validateOptions(options) != null) {
            uiQuestion.options = options
            log.w("Ignoring call to setQuestionOptions as invalid : $options")
        }
        else {
            uiQuestion.options = null
            model.pincerLoader!!.localPincer.questions[questionId]!!.options = options
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setQuestionRegex")
    fun setQuestionRegex(questionId: String, regex: String) = wrap("setQuestionRegex", {
        val uiQuestion = getUiQuestion(questionId)
        if (validateRoles(regex) != null) {
            uiQuestion.regex = regex
            log.w("Ignoring call to setQuestionRegex as invalid : $regex")
        }
        else {
            uiQuestion.regex = null
            model.pincerLoader!!.localPincer.questions[questionId]!!.regex = regex
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setQuestionRoles")
    fun setQuestionRoles(questionId: String, roles: String) = wrap("setQuestionRoles", {
        val uiQuestion = getUiQuestion(questionId)
        if (validateRoles(roles) != null) {
            uiQuestion.roles = roles
            log.w("Ignoring call to setQuestionRoles as invalid : $roles")
        }
        else {
            uiQuestion.roles = null
            model.pincerLoader!!.localPincer.questions[questionId]!!.roles = roles
            model.pincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setAnswer")
    fun setAnswer(questionId: String, wording: String) = wrap("setAnswer", {
        checkLoaded()
        val pincer = model.pincerLoader!!.localPincer
        val participant = pincer.participants[model.getPrincipalId()]!!
        upsertAnswer(pincer = pincer, questionIdOrCode = questionId, participant = participant, wording = wording)
        model.pincerLoader!!.reportChangeOnLocalPincer()
    })

    private fun setAnswerSettings(code: String, wording: String) {
        checkSettingsLoaded()
        val pincer = model.settingsPincerLoader!!.localPincer
        val participant = pincer.participants[model.getPrincipalId()]!!
        upsertAnswer(pincer = pincer, questionIdOrCode = code, participant = participant, wording = wording)
        model.settingsPincerLoader!!.reportChangeOnLocalPincer()
    }

    @JsName("setGivenName")
    fun setGivenName(givenName: String) = wrap("setGivenName", {
        model.givenName = givenName
        if (validateText(givenName) != null) {
            log.w("Ignoring call to setGivenName as invalid : ${validateText(givenName)}")
        }
        else {
            setAnswerSettings(code = "GIVEN_NAME", wording = givenName)
            model.settingsPincerLoader!!.reportChangeOnLocalPincer()
        }
    })

    @JsName("setFamilyName")
    fun setFamilyName(familyName: String) = wrap("setFamilyName", {
        model.familyName = familyName
        if (validateText(familyName) != null) {
            log.w("Ignoring call to setFamilyName as invalid : ${validateText(familyName)}")
        }
        else {
            if (validateText(model.familyName) == null) {
                setAnswerSettings(code = "FAMILY_NAME", wording = familyName)
                model.settingsPincerLoader!!.reportChangeOnLocalPincer()
            }
        }
    })

    @JsName("acceptTerms")
    fun acceptTerms() = wrap("acceptTerms", {
        val now = Clock.System.now().toEpochMilliseconds().toString()
        setAnswerSettings(code = "TERMS_ACCEPTED_AT", wording = now)
        model.settingsPincerLoader!!.reportChangeOnLocalPincer()
    })

    @JsName("logJwt")
    fun logJwt() = wrap("logJwt", {
        log.i("JWT : " + contract!!.getJwt())
    })

}
