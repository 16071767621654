package com.pincer.core.model

import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.js.JsName

@Serializable
data class Device 

(

    @JsName("id") val id: String,

    @JsName("secret") val secret: String,

    @JsName("principalId") val principalId: String,

) 

{

    companion object {
        fun parse(json: String): Device {
            try {
                return Json.decodeFromString<Device>(serializer(), json)
            }
            // catch (jde: JsonDecodingException)
            catch (e: Exception) {
                if (e.toString().contains("JsonDecodingException")) throw InvalidData()
                throw e
            }
        }
    }

    fun json(): String {
        return Json.encodeToString(serializer(), this)
    }

    fun secretHidden(): String {
        return secret.substring(0,14) + "********"
    }

}
