package com.pincer.core.model.patches

import com.pincer.core.model.tree.Candidate
import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlin.js.JsName

//import kotlinx.datetime.duration
//import kotlinx.datetime.participants

@Serializable
data class CandidatePatch

(

    @JsName("start")        var start        : String? = null,
    @JsName("duration")     var duration     : Int? = null,
    @JsName("participants") var participants : String? = null,

) 

{

    fun asCandidate(): Candidate {
        val candidate = Candidate(
            start        = start        ?: throw InvalidData(),
            duration     = duration     ?: throw InvalidData(),
            participants = participants ?: throw InvalidData(),
        )
        candidate.applyPatch(this)
        return candidate
    }

}