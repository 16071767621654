package com.pincer.app

import com.pincer.core.model.tree.Pincer
import com.pincer.core.model.tree.QuestionFormat
import com.pincer.core.model.patches.PincerPatch
import com.pincer.core.model.patches.QuestionPatch
import com.pincer.client.PincerLoader
import com.pincer.client.PincerLoaderStatus
import com.pincer.client.PincerLoaderStatus.FAILED
import com.pincer.client.DEFAULT_SERVER_URL
import com.russhwolf.settings.Settings
import kotlin.js.JsName

class Model {

    init {
        // Options here to deserialize big chunks of the model from local storage.
        // Some parts will always be transient, though so this will be a managed process.
    }

    private val settings: Settings = Settings()

    private val keyPrefix = "pincer.app"

    private val localeKey = "$keyPrefix.locale"
    fun getLocale() = settings.getString(localeKey, defaultValue = "en")
    fun setLocale(locale: String) = settings.putString(localeKey, locale)

    private val advancedOptionsKey = "$keyPrefix.advanced.options"
    fun getAdvancedOptions() = settings.getString(advancedOptionsKey, defaultValue = "hide")
    fun setAdvancedOptions(advancedOptions: String) = settings.putString(advancedOptionsKey, advancedOptions)

    fun showAdvancedOptions(): Boolean = (getAdvancedOptions() == "show")

    private val serverUrlKey = "$keyPrefix.server.url"
    fun getServerUrl() = settings.getString(serverUrlKey, defaultValue = DEFAULT_SERVER_URL)
    fun setServerUrl(serverUrl: String) = settings.putString(serverUrlKey, serverUrl)
    var serverUrlProblem: String? = null

    private val deviceIdKey = "$keyPrefix.device.id"
    fun getDeviceId() = settings.getStringOrNull(deviceIdKey)
    fun setDeviceId(deviceId: String) = settings.putString(deviceIdKey, deviceId)

    private val deviceSecretKey = "$keyPrefix.device.secret"
    fun getDeviceSecret() = settings.getStringOrNull(deviceSecretKey)
    fun setDeviceSecret(deviceSecret: String) = settings.putString(deviceSecretKey, deviceSecret)

    private val principalIdKey = "$keyPrefix.principal.id"
    fun getPrincipalId() = settings.getStringOrNull(principalIdKey)
    fun setPrincipalId(principalId: String) = settings.putString(principalIdKey, principalId)

    @JsName("location")
    var location = ""

    var givenName: String? = null
    var familyName: String? = null
    var newPincerName: String? = null
    var draftChat: String? = null

    var pincerLoader: PincerLoader? = null

    var settingsPincerLoader: PincerLoader? = null

    fun safelyStopPincerLoaders() {
        try {
            if (pincerLoader != null) {
                pincerLoader!!.stop()
            }
            if (settingsPincerLoader != null) {
                settingsPincerLoader!!.stop()
            }
        }
        catch (throwable: Throwable) {
            // log.w("Ignoring throwable while stopping pincerLoaders as part of reset", throwable)
        }
    }

    fun pincerLoadersFailed(): Boolean {
        if (pincerLoader != null && pincerLoader!!.status == PincerLoaderStatus.FAILED) return true
        if (settingsPincerLoader != null && settingsPincerLoader!!.status == PincerLoaderStatus.FAILED) return true
        return false
    }

    fun reset() {
        settings.clear()
        safelyStopPincerLoaders()
    }

    // Set by controller and used by the application
    var unexpectedError: Throwable? = null

    var controllerActivity = mutableListOf<String>()

    @JsName("hasControllerActivity")
    fun hasControllerActivity() = controllerActivity.size > 0

    @JsName("eachControllerActivity")
    fun eachControllerActivity(f: (String) -> Unit) {
        for (ca in controllerActivity) f(ca)
    }

}