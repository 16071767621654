package com.pincer.client

import com.pincer.core.PincerException
import com.pincer.core.model.Device
import com.pincer.core.model.tree.Pincer
import com.pincer.core.model.patches.PincerPatch
import com.pincer.core.SuspendingContract
import com.pincer.core.parse
import io.ktor.client.*
import io.ktor.client.engine.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import com.pincer.core.Logger

const val DEFAULT_SERVER_URL = "https://api.pincer.com"
// const val DEFAULT_SERVER_URL = "http://10.0.2.2:9002"
// const val DEFAULT_SERVER_URL = "http://localhost:9002"

class ClientContract(
    val server: String = DEFAULT_SERVER_URL,
    val engine: HttpClientEngine? = null,
): SuspendingContract() {

    private fun client(jwt: String): HttpClient {
        if (engine == null) {
            return HttpClient() { 
                defaultRequest { headers { append(HttpHeaders.Authorization, "Bearer $jwt") } } 
                expectSuccess = true
                HttpResponseValidator {
                    handleResponseExceptionWithRequest { exception, _ ->
                        val clientException = exception as? ClientRequestException ?: return@handleResponseExceptionWithRequest
                        throw parse(clientException.response.bodyAsText())
                    }
                }
            }
        }
        return HttpClient(engine) { 
            defaultRequest { headers { append(HttpHeaders.Authorization, "Bearer $jwt") } } 
        }
    }

    private fun getHeader(response: HttpResponse, name: String): String {
        val header = response.headers.get(name)
        if (header == null) {
            throw PincerException("Missing $name header (cors issue?)")
        }
        return header
    }

    override suspend fun get(jwt: String, pid: String): Pincer {
        val client = client(jwt)
        val response: HttpResponse = client.request("$server/v1/pincer/$pid") {
            method = HttpMethod.Get
        }
        client.close()
        return Pincer.parse(response.bodyAsText())
    }

    override suspend fun getAt(jwt: String, pid: String, sid: Long): Pincer {
        val client = client(jwt)
        val response: HttpResponse = client.request("$server/v1/pincer/$pid") {
            method = HttpMethod.Get
            parameter("at", "$sid")
        }
        client.close()
        return Pincer.parse(response.bodyAsText())
    }

    override suspend fun getSince(jwt: String, pid: String, sid: Long): PincerPatch {
        val client = client(jwt)
        val response: HttpResponse = client.request("$server/v1/pincer/$pid") {
            method = HttpMethod.Get
            parameter("since", "$sid")
        }
        client.close()
        return PincerPatch.parse(response.bodyAsText())
    }

    override suspend fun fork(jwt: String, pid: String, patch: PincerPatch?): String {
        val client = client(jwt)
        if (pid.length != 12) throw Exception("Client side exception needed here")
        val response: HttpResponse = client.request("$server/v1/pincer/$pid/fork") {
            method = HttpMethod.Post
            if (patch != null) setBody(patch.json())
        }
        client.close()
        return getHeader(response, "Pincer-Pid")
    }

    override suspend fun patch(jwt: String, pid: String, patch: PincerPatch): Long {
        val client = client(jwt)
        val response: HttpResponse = client.request("$server/v1/pincer/$pid") {
            method = HttpMethod.Patch
            setBody(patch.json())
        }
        client.close()
        return getHeader(response, "Pincer-Sid").toLong()
    }

    override suspend fun registerDevice(): Device {
        val response: HttpResponse = HttpClient().request("$server/v1/device") { method = HttpMethod.Post }
        return Device.parse(response.bodyAsText())   
    }

    override suspend fun webSocketUrl(jwt: String, pid: String): String {
        val client = client(jwt)
        val response: HttpResponse = client.request("$server/v1/pincer/$pid/websocketurl") {
            method = HttpMethod.Get
        }
        client.close()
        return response.bodyAsText()
    }

}
