package com.pincer.core.model.patches

import com.pincer.core.model.tree.Pincer
import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.js.JsName

@Serializable
data class PincerPatch

(
    @JsName("pid")             var pid             : String? = null,
    @JsName("createdAt")       var createdAt       : Long? = null,
    @JsName("parent")          var parent          : String? = null,
    @JsName("sid")             var sid             : Long? = null,
    @JsName("sidParent")       var sidParent       : Long? = null,
    @JsName("sidPrincipal")    var sidPrincipal    : String? = null,
    @JsName("days")            var days            : Boolean? = null,
    @JsName("daysStart")       var daysStart       : String? = null,
    @JsName("daysEnd")         var daysEnd         : String? = null,
    @JsName("name")            var name            : String? = null,
    @JsName("description")     var description     : String? = null,
    @JsName("zone")            var zone            : String? = null,
    @JsName("rules")           var rules           : String? = null,
    @JsName("openRoles")       var openRoles       : String? = null,
    @JsName("forkRole")        var forkRole        : String? = null,
    @JsName("isPrincipal")     var isPrincipal     : Boolean? = null,
    @JsName("candidatesStale") var candidatesStale : Boolean? = null,
    @JsName("chats")           var chats           : MutableMap<String,ChatPatch?>? = null,
    @JsName("questions")       var questions       : MutableMap<String,QuestionPatch?>? = null,
    @JsName("participants")    var participants    : MutableMap<String,ParticipantPatch?>? = null,
    @JsName("candidates")      var candidates      : MutableMap<String,CandidatePatch?>? = null,
    @JsName("permissions")     var permissions     : MutableMap<String,PermissionPatch?>? = null,
    @JsName("summaries")       var summaries       : MutableMap<String,SummaryPatch?>? = null,

) 

{

    companion object {
        fun parse(json: String): PincerPatch {
            try {
                return Json.decodeFromString<PincerPatch>(serializer(), json)
            }
            // TODO Why doesn't this work?
            // catch (jde: JsonDecodingException) 
            catch (e: Exception) {
                if (e.toString().contains("JsonDecodingException")) throw InvalidData("Couldn't parse that pincer patch object.")
                throw e
            }
        }
    }

    fun json(): String {
        return Json.encodeToString(serializer(), this)
    }

    fun addChat(id: String, chatPatch: ChatPatch?) {
        if (chats == null) {
            chats = mutableMapOf(id to chatPatch)
        } else {
            chats!![id] = chatPatch
        }
    }

    fun addQuestion(id: String, questionPatch: QuestionPatch?) {
        if (questions == null) {
            questions = mutableMapOf(id to questionPatch)
        } else {
            questions!![id] = questionPatch
        }
    }

    fun addParticipant(principalId: String, participantPatch: ParticipantPatch?) {
        if (participants == null) {
            participants = mutableMapOf(principalId to participantPatch)
        } else {
            participants!![principalId] = participantPatch
        }
    }

    fun addCandidate(id: String, candidatePatch: CandidatePatch?) {
        if (candidates == null) {
            candidates = mutableMapOf(id to candidatePatch)
        } else {
            candidates!![id] = candidatePatch
        }
    }

    fun addPermission(id: String, permissionPatch: PermissionPatch?) {
        if (permissions == null) {
            permissions = mutableMapOf(id to permissionPatch)
        } else {
            permissions!![id] = permissionPatch
        }
    }

    fun addSummary(id: String, summaryPatch: SummaryPatch?) {
        if (summaries == null) {
            summaries = mutableMapOf(id to summaryPatch)
        } else {
            summaries!![id] = summaryPatch
        }
    }

    fun includesRuleChange(): Boolean {
        if (rules != null) return true
        if (participants != null) {
            for (participant in participants!!.values) {
                if (participant!!.rules != null) return true
            }
        }
        return false
    }

}