package com.pincer.core.model.patches

import com.pincer.core.model.tree.Answer
import com.pincer.core.InvalidData
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class AnswerPatch

(

    @JsName("wording")    var wording    : String? = null,

) 

{

    fun asAnswer(): Answer {
        val answer = Answer(
            wording    = wording    ?: throw InvalidData(),
        )
        answer.applyPatch(this)
        return answer
    }

}