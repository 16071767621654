package com.pincer.app

import com.pincer.core.Logger
import kotlin.js.JsName

class ViewModelNode(
        @JsName("key") val key:String, 
        @JsName("parent") val parent: ViewModelNode?
    ) {

    @JsName("sortToken")
    var sortToken:String = key

    @JsName("wording")
    var wording:String = I18n.translate(key)

    @JsName("getCompoundKey") 
    fun getCompoundKey(): String {
        var build = key
        var walk = parent
        while (walk != null) {
            build = walk.key + "-" + build
            walk = walk.parent
        }
        return build
    }

    @JsName("children") 
    val children = mutableListOf<ViewModelNode>()

    @JsName("child") 
    fun child(key: String): ViewModelNode? {
        for (child in children) {
            if (child.key == key) return child
        }
        return null
    }

    @JsName("numberOfChildren")
    fun numberOfChildren() = children.size

    @JsName("hasChildren")
    fun hasChildren() = children.size > 0

    @JsName("eachChild")
    fun eachChild(f: (ViewModelNode) -> Unit) {
        for (child in children) f(child)
    }

    @JsName("getPreviousSibling")
    fun getPreviousSibling(): ViewModelNode? {
        if (parent == null) return null
        val i = parent.children.indexOf(this)
        if (i == 0) return null
        return parent.children[i-1]
    }

    private val prune = mutableMapOf<String,ViewModelNode>()
    private var patrolCount = 0

    fun patrol(f:(ViewModelNode) -> Unit) {

        prune.clear()
        patrolCount = 0
        for (child in children) prune[child.key] = child

        f(this)

        for (child in prune.values) {
            // log.i("Patrol removing node : ${child.key}")
            children.remove(child)
        }

        children.sortBy{ it.sortToken }

    }

    fun hasChild(
        key: String,   
        wording: String? = null,
        sortToken: String? = null,
    ): ViewModelNode {
        patrolCount++
        val build = prune[key] ?: ViewModelNode(key = key, parent = this)
        build.sortToken = sortToken ?: patrolCount.toString().padStart(10, '0')
        build.wording = wording ?: I18n.translate(key)
        if (prune[key] == null) {
            children.add(build)
        }
        else {
            prune.remove(key)
        }
        return build
    }

    fun isLoading() = hasChild(key = "loading")

    fun hasText(wording: String)      = hasChild(key = "text",      wording = wording)
    fun hasInput(wording: String)     = hasChild(key = "input",     wording = wording)
    fun hasMultiline(wording: String) = hasChild(key = "multiline", wording = wording)
    fun hasSelect(wording: String)    = hasChild(key = "select",    wording = wording)
    fun hasCheckbox(wording: String)  = hasChild(key = "checkbox",  wording = wording)
    fun hasButton(wording: String)    = hasChild(key = "button",    wording = wording)
    fun hasOption(wording: String)    = hasChild(key = "option",    wording = wording)
    fun hasParam(wording: String)     = hasChild(key = "param",     wording = wording)
    fun hasAction(wording: String)    = hasChild(key = "action",    wording = wording)
    fun hasHelpText(wording: String)  = hasChild(key = "helpText",  wording = wording)
    fun hasInvalid(wording: String)   = hasChild(key = "invalid",   wording = wording)

    fun mightHaveInvalid(problem: String?) {
        if (problem != null) hasInvalid(I18n.translate(problem))
    }

    @JsName("isFieldNode")
    fun isFieldNode(): Boolean {
        for (child in children) {
            if (child.key == "text") return true
            if (child.key == "input") return true
            if (child.key == "multiline") return true
            if (child.key == "select") return true
            if (child.key == "checkbox") return true
            if (child.key == "button") return true
        }
        return false
    }

    /*
    fun hasField(key: String, fieldType: String, value: String, action: String? = null, helpText: String? = null) {
        val field = hasChild(key = key, )
    }

    Most children
    */

}
