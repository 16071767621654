package com.pincer.core

import com.pincer.core.model.Device
import com.pincer.core.model.tree.Pincer
import com.pincer.core.model.patches.PincerPatch

interface Contract {

    suspend fun _get(jwt: String, pid: String): Pincer

    suspend fun _getAt(jwt: String, pid: String, sid: Long): Pincer

    suspend fun _getSince(jwt: String, pid: String, sid: Long): PincerPatch

    suspend fun _fork(jwt: String, pid: String, patch: PincerPatch?): String

    suspend fun _patch(jwt: String, pid: String, patch: PincerPatch): Long

    suspend fun _registerDevice(): Device

    suspend fun _webSocketUrl(jwt: String, pid: String): String

}

// Unfortunately, we can't implement an non-suspend interface and declare it as suspending. Hence these two ogres...

abstract class SuspendingContract(): Contract {

    abstract suspend fun get(jwt: String, pid: String): Pincer
    override suspend fun _get(jwt: String, pid: String): Pincer = get(jwt, pid) 

    abstract suspend fun getAt(jwt: String, pid: String, sid: Long): Pincer
    override suspend fun _getAt(jwt: String, pid: String, sid: Long): Pincer = getAt(jwt, pid, sid) 

    abstract suspend fun getSince(jwt: String, pid: String, sid: Long): PincerPatch
    override suspend fun _getSince(jwt: String, pid: String, sid: Long): PincerPatch = getSince(jwt, pid, sid) 

    abstract suspend fun fork(jwt: String, pid: String, patch: PincerPatch?): String
    override suspend fun _fork(jwt: String, pid: String, patch: PincerPatch?): String = fork(jwt, pid, patch) 

    abstract suspend fun patch(jwt: String, pid: String, patch: PincerPatch): Long
    override suspend fun _patch(jwt: String, pid: String, patch: PincerPatch): Long = patch(jwt, pid, patch)

    abstract suspend fun registerDevice(): Device
    override suspend fun _registerDevice(): Device = registerDevice()

    abstract suspend fun webSocketUrl(jwt: String, pid: String): String
    override suspend fun _webSocketUrl(jwt: String, pid: String): String = webSocketUrl(jwt, pid)

}

abstract class NonSuspendingContract(): Contract {

    abstract fun get(jwt: String, pid: String): Pincer
    override suspend fun _get(jwt: String, pid: String): Pincer = get(jwt, pid) 

    abstract fun getAt(jwt: String, pid: String, sid: Long): Pincer
    override suspend fun _getAt(jwt: String, pid: String, sid: Long): Pincer = getAt(jwt, pid, sid) 

    abstract fun getSince(jwt: String, pid: String, sid: Long): PincerPatch
    override suspend fun _getSince(jwt: String, pid: String, sid: Long): PincerPatch = getSince(jwt, pid, sid) 

    abstract fun fork(jwt: String, pid: String, patch: PincerPatch?): String
    override suspend fun _fork(jwt: String, pid: String, patch: PincerPatch?): String = fork(jwt, pid, patch) 

    abstract fun patch(jwt: String, pid: String, patch: PincerPatch): Long
    override suspend fun _patch(jwt: String, pid: String, patch: PincerPatch): Long = patch(jwt, pid, patch)

    abstract fun registerDevice(): Device
    override suspend fun _registerDevice(): Device = registerDevice()

    abstract fun webSocketUrl(jwt: String, pid: String): String
    override suspend fun _webSocketUrl(jwt: String, pid: String): String = webSocketUrl(jwt, pid)

}

// 1678798465 : Tuesday, 14-Mar-23 12:04:43 UTC
val TERMS_LAST_CHANGED_AT_MILLIS = 1678798465 * 1000L
val TEMPLATE_PID          = "BBBBBBBBBBBB"
val SETTINGS_TEMPLATE_PID = "CCCCCCCCCCCC"