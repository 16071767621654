package com.pincer.client

import com.pincer.core.Logger

external fun empName(): String
actual   fun  mpName(): String = empName()

external fun empBase64UrlEncode(string: String): String
actual   fun  mpBase64UrlEncode(string: String): String = empBase64UrlEncode(string)

external fun empHmacSha256(string: String, secret: String): String 
actual   fun  mpHmacSha256(string: String, secret: String): String = empHmacSha256(string, secret)

external fun empOpenWebsocket(url: String, callback: (suspend () -> Unit)): Unit
actual   fun  mpOpenWebsocket(url: String, log: Logger, callback: (suspend () -> Unit)): Unit = empOpenWebsocket(url, callback)

external fun empCloseWebsocket(url: String): Unit
actual   fun  mpCloseWebsocket(url: String, log: Logger): Unit = empCloseWebsocket(url)

