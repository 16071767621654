@file:JsExport

import com.pincer.core.Logger
import com.pincer.app.Application
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope

@DelicateCoroutinesApi
@Suppress("NON_EXPORTABLE_TYPE")
val application = Application(coroutineScope = GlobalScope, log =Logger(format = "noTags"))
