package com.pincer.core.model.tree

import com.pincer.core.Logger
import com.pincer.core.InvalidData
import com.pincer.core.ProblemWithRequest
import com.pincer.core.model.patches.ChatPatch
import com.pincer.core.model.patches.PincerPatch
import com.pincer.core.model.patches.ParticipantPatch
import com.pincer.core.model.patches.CandidatePatch
import com.pincer.core.model.patches.QuestionPatch
import com.pincer.core.model.patches.PermissionPatch
import com.pincer.core.model.patches.SummaryPatch
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.js.JsName

@Serializable
data class Pincer 

(
    
    @JsName("pid")             var pid             : String,
    @JsName("createdAt")       var createdAt       : Long,
    @JsName("parent")          var parent          : String?,
    @JsName("sid")             var sid             : Long,
    @JsName("sidParent")       var sidParent       : Long?,
    @JsName("sidPrincipal")    var sidPrincipal    : String,
    @JsName("days")            var days            : Boolean = false,
    @JsName("daysStart")       var daysStart       : String = "08:00",
    @JsName("daysEnd")         var daysEnd         : String = "20:00",
    @JsName("name")            var name            : String? = null,
    @JsName("description")     var description     : String? = null,
    @JsName("zone")            var zone            : String = "US/Eastern",
    @JsName("rules")           var rules           : String = "true",
    @JsName("openRoles")       var openRoles       : String = "attendee",
    @JsName("forkRole")        var forkRole        : String? = null,
    @JsName("isPrincipal")     var isPrincipal     : Boolean = false,
    @JsName("candidatesStale") var candidatesStale : Boolean = false,
    @JsName("chats")           val chats           : MutableMap<String,Chat> = mutableMapOf<String,Chat>(),
    @JsName("questions")       val questions       : MutableMap<String,Question> = mutableMapOf<String,Question>(),
    @JsName("participants")    val participants    : MutableMap<String,Participant> = mutableMapOf<String,Participant>(),
    @JsName("candidates")      val candidates      : MutableMap<String,Candidate> = mutableMapOf<String,Candidate>(),
    @JsName("permissions")     val permissions     : MutableMap<String,Permission> = mutableMapOf<String,Permission>(),
    @JsName("summaries")       val summaries       : MutableMap<String,Summary> = mutableMapOf<String,Summary>(),

) 

{

    companion object {

        fun parse(json: String): Pincer {
            try {
                return Json.decodeFromString<Pincer>(serializer(), json)
            }
            // catch (jde: JsonDecodingException)
            catch (e: Exception) {
                if (e.toString().contains("JsonDecodingException")) throw InvalidData()
                throw e
            }
        }

        // fun raw() = Pincer(pid = "", createdAt = 0, parent = null, sid = 0, sidParent = null, sidPrincipal = "")

    }

    fun json(): String {
        return Json.encodeToString(serializer(), this)
    }

    fun copyViaJson(): Pincer {
        return parse(json())
    }

    private fun <V,W> updateMap(map: MutableMap<String, V>, patchMap: MutableMap<String, W?>?, asFull: W.() -> V, applyPatch: V.(W) -> Unit) {
        patchMap?.forEach { (key,theirs) ->
            val ours = map[key]
            if (ours == null && theirs == null) {
                // no action
            } else if (ours != null && theirs == null) {
                map.remove(key)
            } else if (ours == null && theirs != null) {
                map[key] = theirs.asFull()
            } else {
                ours!!.applyPatch(theirs!!)
            }
        }
    }

    fun applyPatch(patch: PincerPatch) {
        pid             = patch.pid             ?: pid
        createdAt       = patch.createdAt       ?: createdAt
        parent          = patch.parent          ?: parent
        sid             = patch.sid             ?: sid
        sidParent       = patch.sidParent       ?: sidParent
        sidPrincipal    = patch.sidPrincipal    ?: sidPrincipal
        days            = patch.days            ?: days
        daysStart       = patch.daysStart       ?: daysStart
        daysEnd         = patch.daysEnd         ?: daysEnd
        name            = patch.name            ?: name
        description     = patch.description     ?: description
        zone            = patch.zone            ?: zone
        rules           = patch.rules           ?: rules
        openRoles       = patch.openRoles       ?: openRoles
        forkRole        = patch.forkRole        ?: forkRole
        isPrincipal     = patch.isPrincipal     ?: isPrincipal
        candidatesStale = patch.candidatesStale ?: candidatesStale
        updateMap<Chat,ChatPatch>(              chats,        patch.chats,        ChatPatch::asChat,               Chat::applyPatch)
        updateMap<Question,QuestionPatch>(      questions,    patch.questions,    QuestionPatch::asQuestion,       Question::applyPatch)
        updateMap<Participant,ParticipantPatch>(participants, patch.participants, ParticipantPatch::asParticipant, Participant::applyPatch)
        updateMap<Candidate,CandidatePatch>(    candidates,   patch.candidates,   CandidatePatch::asCandidate,     Candidate::applyPatch)
        updateMap<Permission,PermissionPatch>(  permissions,  patch.permissions,  PermissionPatch::asPermission,   Permission::applyPatch)
        updateMap<Summary,SummaryPatch>(        summaries,    patch.summaries,    SummaryPatch::asSummary,         Summary::applyPatch)
    }

    fun diff(ancestor: Pincer): PincerPatch {
        val patch = PincerPatch(
            pid             =             pid.takeIf { it != ancestor.pid },
            createdAt       =       createdAt.takeIf { it != ancestor.createdAt },
            parent          =          parent.takeIf { it != ancestor.parent },
            sid             =             sid.takeIf { it != ancestor.sid },
            sidParent       =       sidParent.takeIf { it != ancestor.sidParent },
            sidPrincipal    =    sidPrincipal.takeIf { it != ancestor.sidPrincipal },
            days            =            days.takeIf { it != ancestor.days },
            daysStart       =       daysStart.takeIf { it != ancestor.daysStart },
            daysEnd         =         daysEnd.takeIf { it != ancestor.daysEnd },
            name            =            name.takeIf { it != ancestor.name },
            description     =     description.takeIf { it != ancestor.description },
            zone            =            zone.takeIf { it != ancestor.zone },
            rules           =           rules.takeIf { it != ancestor.rules },
            openRoles       =       openRoles.takeIf { it != ancestor.openRoles },
            forkRole        =        forkRole.takeIf { it != ancestor.forkRole },
            isPrincipal     =     isPrincipal.takeIf { it != ancestor.isPrincipal },
            candidatesStale = candidatesStale.takeIf { it != ancestor.candidatesStale },
        )
        for (id in chats.keys) {
            val ourChat = chats[id]!!
            var theirChat = ancestor.chats[id]
            if (theirChat == null) {
                patch.addChat(id, ourChat.asPatch())
            } else if (ourChat != theirChat) {
                patch.addChat(id, ourChat.diff(theirChat))
            }
        }
        for (id in ancestor.chats.keys) {
            if (chats[id] == null) {
                patch.addChat(id, null)
            }
        }
        for (id in questions.keys) {
            val ourQuestion = questions[id]!!
            var theirQuestion = ancestor.questions[id]
            if (theirQuestion == null) {
                patch.addQuestion(id, ourQuestion.asPatch())
            } else if (ourQuestion != theirQuestion) {
                patch.addQuestion(id, ourQuestion.diff(theirQuestion))
            }
        }
        for (id in ancestor.questions.keys) {
            if (questions[id] == null) {
                patch.addQuestion(id, null)
            }
        }
        for (id in participants.keys) {
            val ourParticipant = participants[id]!!
            var theirParticipant = ancestor.participants[id]
            if (theirParticipant == null) {
                patch.addParticipant(id, ourParticipant.asPatch())
            } else if (ourParticipant != theirParticipant) {
                patch.addParticipant(id, ourParticipant.diff(theirParticipant))
            }
        }
        for (id in ancestor.participants.keys) {
            if (participants[id] == null) {
                patch.addParticipant(id, null)
            }
        }
        for (id in candidates.keys) {
            val ourCandidate = candidates[id]!!
            var theirCandidate = ancestor.candidates[id]
            if (theirCandidate == null) {
                patch.addCandidate(id, ourCandidate.asPatch())
            } else if (ourCandidate != theirCandidate) {
                patch.addCandidate(id, ourCandidate.diff(theirCandidate))
            }
        }
        for (id in ancestor.candidates.keys) {
            if (candidates[id] == null) {
                patch.addCandidate(id, null)
            }
        }
        for (id in permissions.keys) {
            val ourPermission = permissions[id]!!
            var theirPermission = ancestor.permissions[id]
            if (theirPermission == null) {
                patch.addPermission(id, ourPermission.asPatch())
            } else if (ourPermission != theirPermission) {
                patch.addPermission(id, ourPermission.diff(theirPermission))
            }
        }
        for (id in summaries.keys) {
            val ourSummary = summaries[id]!!
            var theirSummary = ancestor.summaries[id]
            if (theirSummary == null) {
                patch.addSummary(id, ourSummary.asPatch())
            } else if (ourSummary != theirSummary) {
                patch.addSummary(id, ourSummary.diff(theirSummary))
            }
        }
        for (id in ancestor.summaries.keys) {
            if (summaries[id] == null) {
                patch.addSummary(id, null)
            }
        }
        return patch
    }

}
