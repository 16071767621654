package com.pincer.client

import com.pincer.core.Logger
import com.pincer.core.model.tree.Pincer
import com.pincer.core.model.patches.PincerPatch
import com.pincer.core.model.patches.QuestionPatch
import com.pincer.core.model.patches.ParticipantPatch
import com.pincer.core.model.patches.AnswerPatch
import com.pincer.core.ProblemWithRequest
import com.pincer.core.PincerException
import kotlinx.coroutines.Job
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.js.JsName

class PincerLoader (
    val contract: ClientSession,
    val pid: String,
    val coroutineScope: CoroutineScope,
    var syncSeconds: Int = 60,
    val log: Logger,
) 

{

    companion object {

        // The option to get a Loader with just a pid and scope.
        // Will register a new device everytime.
        // Default server and logging. 
        suspend fun anonymous(
            pid: String,
            coroutineScope: CoroutineScope,
            syncSeconds: Int = 60,
            log: Logger = Logger(),
            server: String = DEFAULT_SERVER_URL,
        ): PincerLoader {
            log.i("Anonymous access request.")
            val clientSession = ClientSession.newDevice(server = server, log = log)
            val pincerLoader = PincerLoader(contract = clientSession, pid = pid, coroutineScope = coroutineScope, syncSeconds = syncSeconds, log = log)
            pincerLoader.start()
            return pincerLoader
        }

    }

    @JsName("status") 
    var status: PincerLoaderStatus = PincerLoaderStatus.NOT_STARTED ; private set 

    val changes = Changes(log)

    @JsName("pincer") 
    lateinit var localPincer: Pincer ; private set

    lateinit var remotePincer: Pincer ; private set

    lateinit var uiPincer: PincerPatch ; private set

    fun pincerLoaded() = ::localPincer.isInitialized

    private lateinit var watcher: Job

    private var syncCountdown = syncSeconds

    private lateinit var webSocketUrl: String

    // private var syncCurrentlyFailingWith: ProblemWithRequest? = null

    suspend fun start() { 
        coroutineScope.launch {
            if (status != PincerLoaderStatus.NOT_STARTED) throw Exception("Already started?")
            status = PincerLoaderStatus.INITIALIZING
            try {
                log.i("PincerLoader initializing. Initial GET for $pid ... ")
                status = PincerLoaderStatus.LOADING
                localPincer = contract.get(pid)
                remotePincer = localPincer.copyViaJson()
                uiPincer = PincerPatch()
                uiPincer.addQuestion("NEW", QuestionPatch(format = "SIMPLE"))
                uiPincer.participants = mutableMapOf(contract.principalId to ParticipantPatch(answers = mutableMapOf<String,AnswerPatch?>()))
                status = PincerLoaderStatus.DORMANT
                log.i(" ... initialization complete")
                if (syncSeconds > 0) {
                    log.i("Starting sync loop, $syncSeconds seconds")
                    watcher = launch {
                        while(true) {
                            delay(1000L)
                            syncCountdown--
                            if (syncCountdown <= 0) {
                                sync()
                                syncCountdown = syncSeconds
                            }
                        }
                    }
                }
                try {
                    webSocketUrl = contract.webSocketUrl(pid)
                    log.i("Requesting websocket for $webSocketUrl")
                    mpOpenWebsocket(webSocketUrl, log, { 
                        log.i("Websocket Ping")
                        syncCountdown = 0 
                    })
                }
                catch (e: Exception) {
                    log.w("Failed to start websocket", e)
                }
            }
            catch (e: Exception) {
                try { 
                    stop() 
                }
                catch (ignored: Throwable) { 
                    log.i("Ignoring throwable stopping pincerLoader on failure: $ignored")
                } 
                status = PincerLoaderStatus.FAILED
                log.i("PincerLoader failed in start ...")
                log.e(e.stackTraceToString())
            }
            changes.emit()
        }
    }

    fun stop() {
        log.i("PincerLoader stopping")
        if (::watcher.isInitialized) {
            log.i("Attempting to cancel watcher")
            try { watcher.cancel() }
            catch (throwable: Throwable) { log.w("Problem cancelling watcher", throwable) }
        }
        log.i("Attempting to close websocket $webSocketUrl")
        try { mpCloseWebsocket(webSocketUrl, log) }
        catch (throwable: Throwable) { log.w("Problem closing websocket", throwable) }
        status = PincerLoaderStatus.STOPPED
    }

    suspend fun sync() {
        if (status != PincerLoaderStatus.DORMANT) {
            log.i("No sync as not dormant: $status")
            return
        }
        status = PincerLoaderStatus.SYNC
        try {
            var rerunLimit = 10
            do {
                // log.i("Sync ${localPincer.pid} ...")
                var somethingHappened = false
                // log.i("Local: $localPincer")
                // log.i("Remote: $remotePincer")
                status = PincerLoaderStatus.SYNC_DOWN
                val remotePatch = contract.getSince(remotePincer.pid, remotePincer.sid)
                val localPatch = localPincer.diff(remotePincer)
                val empty = PincerPatch()
                if (remotePatch == empty && localPatch == empty) {
                    // log.i("Patches empty, no action taken")
                }
                else {
                    somethingHappened = true
                    if (localPatch != empty) {
                        // log.i("Local patch : ${localPatch.json()}")
                    }
                    if (remotePatch != empty) {
                        // log.i("Remote patch : ${remotePatch.json()}")
                    }
                    // Check for conflicts
                    val remotePatchFirst: Pincer = remotePincer.copyViaJson()
                    remotePatchFirst.applyPatch(remotePatch)
                    remotePatchFirst.applyPatch(localPatch)
                    val localPatchFirst: Pincer = remotePincer.copyViaJson()
                    localPatchFirst.applyPatch(localPatch)
                    localPatchFirst.applyPatch(remotePatch)
                    if (remotePatchFirst != localPatchFirst) {
                        log.i("Conflicts ... ")
                        log.i(localPatchFirst.json())
                        log.i(remotePatchFirst.json())
                        status = PincerLoaderStatus.CONFLICT
                        return
                    }
                    localPincer.applyPatch(remotePatch)
                    remotePincer.applyPatch(remotePatch)
                    if (localPatch != empty) {
                        // log.i("Sync Up")
                        status = PincerLoaderStatus.SYNC_UP
                        // This try/catch makes sense if there's some recoverable thing we want to allow.
                        // Probably needed for at least temporary network problems.
                        // try { 
                            val sid = contract.patch(pid = remotePincer.pid, localPatch)
                            if (sid == remotePincer.sid) {
                                throw PincerException("Looks like patch had no effect. Permissions issue?")
                            }
                            val echoPatch = contract.getSince(remotePincer.pid, remotePincer.sid)
                            if (echoPatch.sid!! < sid) {
                                log.i("Remote sid too low? ${echoPatch.sid} < $sid")
                                status = PincerLoaderStatus.CONFLICT
                                return
                            }
                            remotePincer.applyPatch(echoPatch)
                            localPincer.applyPatch(echoPatch)
                        /*
                            syncCurrentlyFailingWith = null
                        }
                        catch (problemWithRequest: ProblemWithRequest) {
                            log.w("ProblemWithRequest: ${problemWithRequest.message}")
                            syncCurrentlyFailingWith = problemWithRequest
                            somethingHappened = false
                        }
                        */
                    }
                }
                if (somethingHappened) {
                    // log.i("Something Happened, will re-run sync")
                    rerunLimit --
                    delay(1000L)
                }
            } while (somethingHappened && rerunLimit > 0)
            status = PincerLoaderStatus.DORMANT
            // log.i("After sync local: $localPincer")
            // log.i("After sync remote: $remotePincer")
            // log.i("===================================")
        }
        catch (e: Exception) {
            stop()
            status = PincerLoaderStatus.FAILED
            log.i("PincerLoader failed in sync ...")
            log.e(e.stackTraceToString())
        }
        changes.emit()
    } 

    fun reportChangeOnLocalPincer() {
        syncCountdown = 0
    }

    fun loadingByStatus(): Float {
        when (status) {
            PincerLoaderStatus.INITIALIZING -> return 0.2f
            PincerLoaderStatus.CREATING -> return 0.3f
            PincerLoaderStatus.SYNC -> return 0.4f
            PincerLoaderStatus.SYNC_UP -> return 0.5f
            PincerLoaderStatus.SYNC_DOWN -> return 0.6f
            else -> return 1f
        }
    }

}

enum class PincerLoaderStatus {
    NOT_STARTED, INITIALIZING, CREATING, LOADING, SYNC, SYNC_UP, SYNC_DOWN, FAILED, DORMANT, CONFLICT, STOPPED
}
