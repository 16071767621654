package com.pincer.app

import com.pincer.core.Logger

// A single copy of the translations will be included in the source of all applications.
// At some point we could make this a build parameter so that different versions could be
// shipped in different parts of the world with a corresponding choice of default.
// Changes to locale made through the controller overwrite these strings.

class I18n {

    companion object {

        val t = mutableMapOf<String,String>()

        fun hasTranslation(key: String): Boolean {
            return (t[key] != null)
        }

        fun translate(key: String, vararg params: String): String {
            var build = t[key] ?: key
            var count = 0
            for (param in params) {
                count++
                build = build.replace("%$count%", param)
            }
            return build
        }

        var loadedLocale = "en"

        fun defaults() {
            t["root"] = "Pincer"
            t["pid"] = "Reference"
            t["shareableLink"] = "Shareable Link"
            t["summaries"] = "Your Pincers"
            t["settingsForm"] = "Settings"
            t["advancedOptions"] = "Advanced Options"
            t["showAdvancedOptions"] = "Choose this to display more advanced settings and features throughout the application."
            t["settings"] = "Settings"
            t["pincerName"] = "Pincer Name"
            t["pincerDescription"] = "Pincer Description"
            t["pincerRules"] = "Base Rules"
            t["rules"] = "Availability Rules"
            t["participantRules"] = "Availability Rules for this Participant"
            t["roles"] = "Participant Roles"
            t["locale"] = "Language"
            t["serverUrl"] = "Server"
            t["problem"] = "Problem"
            t["badLocale"] = "Please enter a supported two letter language code, e.g. 'en' or 'es'."
            t["badServer"] = "Can't connect to the server. Please check the address."
            t["candidates"] = "Possibilities"
            t["chats"] = "Conversation"
            t["name"] = "Name"
            t["wording"] = "Wording"
            t["participants"] = "Participants"
            t["name"] = "Name"
            t["givenName"] = "First Name"
            t["familyName"] = "Surname"
            t["newPincer"] = "New Pincer"
            t["termsAndConditions"] = "Terms and Conditions"
            t["termsSummary"] = "Click here to inidicate you have read and agree to the terms of the %1%."
            t["pincerCustomerAgreement"] = "Pincer Customer Agreement" 
            t["acceptTerms"] = "Accept Terms and Conditions"
            t["allowedValues"] = "Possible Values"
            t["true"] = "True"
            t["false"] = "False"
            t["en"] = "English"
            t["es"] = "Spanish"
            t["fr"] = "French"
            t["resetDevice"] = "Reset Device"
            t["permissions"] = "Permissions"
            t["addChatMessage"] = "Add Message"
            t["draftChat"] = "Message"
            t["addChat"] = "Send"
            t["sparticipantRules"] = "Your Rules"
            t["unexpectedError"] = "An unexpected error occurred"
            t["backgroundFailed"] = "An important background process failed"
            t["setUpForm"] = "Set up your account"
            t["loadPincer"] = "Load Pincer"
            t["loading"] = "Loading ..."
            t["questions"] = "Questions"
            t["newQuestion"] = "Create New Question"
            t["question-code"] = "Question Code"
            t["question-format"] = "Question Type"
            t["question-wording"] = "Wording of Question"
            t["question-help-text"] = "Help Text"
            t["question-options"] = "Options"
            t["question-roles"] = "Roles to Ask"
            t["question-regex"] = "Regular Expression"
            t["question-regex-help"] = "This is an advanced feature. You have been warned! Use this to specify a strict pattern or format that answers need to fit. Here's [a good place to learn about regular expressions](https://regexlearn.com/)"
            t["question-code-help"] = "Choose a simple label for you to manage the answers. It won't be shown to your users. Examples: FNAME, AGE, EMAIL, ZIP_CODE"
            t["question-options-help"] = "Enter each option on a different line. Try to keep them short."
            t["question-roles-help"] = "This is an advanced feature. Enter a comma separated list of role names."
            t["answers"] = "Answers"
            t["newPincerName"] = "New Pincer Name"
            t["createNewPincer"] = "Create New Pincer"
            t["takePart"] = "Take Part"
            t["SIMPLE"] = "One-line Text"
            t["MULTILINE"] = "Multi-line Text"
            t["DATE"] = "Date"
            t["TIME"] = "Time"
            t["DATETIME"] = "Date and Time"
            t["EMAIL"] = "Email Address"
            t["PHONE"] = "Phone Number"
            t["REGEX"] = "Text in a Specific Format"
            t["EMAIL"] = "Email Address"
            t["SELECT"] = "Select from List"
            t["CHECKBOX"] = "Checkbox"
            t["addQuestion"] = "Create New Question"
            t["invalid-missing"] = "Please enter something"
            t["invalid-too-short"] = "This text is too short"
            t["invalid-too-long"] = "This text is too long"
            t["invalid-problem-word"] = "This text contains a problem word or phrase"
            t["invalid-problem-character"] = "This text contains a problem character"
            t["invalid-format"] = "Please use the correct format"
            t["invalid-question-code"] = "Please use just captial letters and underscores."
            t["invalid-not-enough-options"] = "Please enter at least two options."
        } 

        init { defaults() }

        fun load(locale: String, log: Logger) {
            if (locale == loadedLocale) return
            log.i("Updating locale $loadedLocale -> $locale")
            loadedLocale = locale
            defaults()
            // TODO ... async network call (local cache?)
            if (locale == "es") {
                t["settingsForm"] = "Configuración"
                t["en"] = "Inglés"
                t["es"] = "Español"
                t["fr"] = "Francés"
            }
            else if (locale == "fr") {
                t["settingsForm"] = "Configuration"
                t["en"] = "Anglais"
                t["es"] = "Espagnol"
                t["fr"] = "Français"
            }
            else {
                log.w("No tokens for language: $locale")
            }
        }

    }

}