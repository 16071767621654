package com.pincer.core.functions

import com.pincer.core.InvalidData
import com.pincer.core.model.tree.Pincer
import com.pincer.core.model.tree.Question
import com.pincer.core.model.tree.Answer
import com.pincer.core.model.tree.QuestionFormat

class Validation() {
    val problems = mutableSetOf<String>()
    fun add(problem: String?) {
        if (problem != null) problems.add(problem)
    }
}

fun validatePincer(pincer: Pincer): MutableSet<String> {
    val validation = Validation()
    validation.add(validatePid(pincer.pid))
    validation.add(validateTimestamp(pincer.createdAt))
    validation.add(validateSid(pincer.sid))
    validation.add(validateSidParent(pincer.sidParent))
    validation.add(validateLocalTime(pincer.daysStart))
    validation.add(validateLocalTime(pincer.daysEnd))
    validation.add(validateText(pincer.name))
    validation.add(validateBlockText(pincer.description))
    // See note below about JEXL
    // wrap(problems) { validateRules(pincer.rules))
    validation.add(validateRoles(pincer.openRoles))
    for (chat in pincer.chats.values) {
        validation.add(validateText(chat.wording))
        validation.add(validateRoles(chat.roles))
    }
    for (question in pincer.questions.values) {
        validation.problems.addAll(validateQuestion(question).problems)
    }
    for (participant in pincer.participants.values) {
        validation.add(validateText(participant.name))
        validation.add(validateRoles(participant.roles))
        // validation.add(validateRules(participant.rules))
        for (entry in participant.answers.entries) {
            validation.add(validateAnswer(pincer.questions[entry.key], entry.value.wording))
        }
    }
    for (permission in pincer.permissions.values) {
        validation.add(validateRole(permission.role))
        validation.add(validateScope(permission.scope))
    }
    return validation.problems
}

fun validateQuestion(question: Question): Validation {
    val validation = Validation()
    validation.add(validateText(question.wording))
    validation.add(validateQuestionFormat(question.format))
    validation.add(validateRoles(question.roles))
    validation.add(validateBlockText(question.helpText))
    if (question.format == QuestionFormat.SELECT.toString()) {
        validation.add(validateOptions(question.options))
    }
    if (question.format == QuestionFormat.REGEX.toString()) {
        validation.add(validateRegex(question.regex))
    }
    validation.add(validateRoles(question.roles))
    return validation
}

private val pidPattern = Regex("[BCDFGHJKMNPQRSTVWXYZ]{12}")
fun validatePid(pid: String): String? {
    if (pidPattern.matches(pid)) return null
    return "invalid-format"
}

fun validateTimestamp(timestamp: Long): String? {
    if (timestamp > 1680000000L) return null
    return "invalid-timestamp"
}

fun validateTimestampString(timestamp: String): String? {
    try { return validateTimestamp(timestamp.toLong()) }
    catch (e: NumberFormatException) { return "invalid-timestamp" }
}

fun validateSid(sid: Long): String? {
    if (sid < 0L) return "invalid-timestamp"
    return null
}

fun validateSidParent(sidParent: Long?): String? {
    if (sidParent == null) return null
    return validateSid(sidParent)
}

private val idPattern = Regex("^[a-z]{3}_[a-zA-z0-9]{12}$")
fun validateId(id: String): String? {
    if (idPattern.matches(id)) return null
    return "invalid-format"
}

private val localePattern = Regex("^[a-z]{2}$") // for now
fun validateLocale(locale: String): String? {
    if (localePattern.matches(locale)) return null
    return "invalid-format"
}

// TODO, for answers, these need to handle a specified locale 
// (but we could also keep this ISO stuff as a layer below that)
// (so controller and view model do the back and forth)
private val localTimePattern = Regex("^(?:(?:[01][0-9])|(?:2[0123])):[012345][0-9](?::[0-5]\\d)?$")
fun validateLocalTime(localTime: String): String? {
    // try { LocalTime.parse(localTime) }
    // catch (e: DateTimeParseException) { return "invalid-format" }
    if (localTimePattern.matches(localTime)) return null
    return "invalid-format"
}

private val localDatePattern = Regex("^\\d{4}-([0]\\d|1[0-2])-([0-2]\\d|3[01])$")
fun validateLocalDate(localDate: String): String? {
    if (localDatePattern.matches(localDate)) return null
    return "invalid-format"
}

private val localDateTimePattern = Regex("^\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d(?::[0-5]\\d)?$")
fun validateLocalDateTime(localDateTime: String): String? {
    if (localDateTimePattern.matches(localDateTime)) return null
    return "invalid-format"
}

private val zonePattern = Regex("^[a-z]+/[a-z_]+(?:/[a-z_]+)?$", RegexOption.IGNORE_CASE)
fun validateZone(zone: String): String? {
    // TODO Work out how to test java time for validity ... multiplatform?
    // try { ZoneOffset.of(zone) }
    //catch (e: DateTimeException) { return "invalid-zone" }
    if (zonePattern.matches(zone)) return null
    return "invalid-zone"
}

private val emailPattern = Regex("[-a-z0-9~!$%^&*_=+}{'?]+(\\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\\.[-a-z0-9_]+)*\\.([a-z]{2,})|([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}))(:[0-9]{1,5})?")
fun validateEmail(email: String): String? {
    if (emailPattern.matches(email)) return null
    return "invalid-format"
}

private val phonePattern = Regex("\\+?[0-9\\(\\)\\- ]*[0-9][0-9][0-9\\(\\)\\- ]*(?:\\(?(?:e\\.?|x\\.?|ex\\.?|ext\\.?|exten\\.?|extension) ?[0-9]+\\)?)?")
fun validatePhone(phone: String): String? {
    if (phonePattern.matches(phone)) return null
    return "invalid-format"
}

private val scopePattern = Regex("^[a-z]+(?:\\.[a-z]+)*$")
fun validateScope(scope: String): String? {
    if (scopePattern.matches(scope)) return null
    return "invalid-format"
}

/*
This is the method of that name from the server code.
It predates this validation layer, but isn't simple to bring 
in as it stands because of JEXL not being multiplaform.

fun validateRules(rules: String): String? {
    try {
        durationsBroad(rules)
    }
    catch (je: JexlException) {
        throw InvalidData("Something wrong with those rules.")
    }
}
*/

private val rolePattern = Regex("^[A-Z]+$")
fun validateRole(role: String): String? {
    if (rolePattern.matches(role)) return null
    return "invalid-format"
}

private val rolesPattern = Regex("^[a-z]+(,[a-z]+)*$")
fun validateRoles(roles: String?): String? {
    if (roles == null || roles == "") return null
    if (rolesPattern.matches(roles)) return null
    return "invalid-format"
}

fun validateQuestionFormat(questionFormat: String): String? {
    try { QuestionFormat.valueOf(questionFormat) }
    catch (e: IllegalArgumentException) { return "invalid-option" }
    catch (e: IllegalStateException) { return "invalid-option" }    // js throws this for some reason
    return null
}

private val questionCodePattern = Regex("^[A-Z_\\-0-9]+$")
fun validateQuestionCode(questionCode: String?): String? {
    if (questionCode == null) return null
    if (questionCode.trim() == "") return "invalid-missing"
    if (questionCodePattern.matches(questionCode)) return null
    return "invalid-question-code"
}

fun validateOptions(options: String?): String? {
    if (options == null) return null
    if (!options.contains("\n")) return "invalid-not-enough-options"
    options.lines().forEach { 
        val problem = validateText(it)
        if (problem != null) {
            return problem
        }
    }
    return null
}

fun validateRegex(regex: String?): String? {
    if (regex == null) return null
    if (regex.trim().length == 0) return "invalid-missing"
    // Multiplatorm issues here. Couldn't even generalise from 
    // java.util.regex.PatternSyntaxException to Exception - hence Throwable
    try { Regex(regex) }
    catch (t: Throwable) { return "invalid-format" }
    return null
}

fun validateRegex(string: String?, regex: String?): String? {
    if (string == null) return null
    if (regex == null || regex.length == 0 || Regex("^${regex}$").matches(string)) return validateText(string)
    return "invalid-format"
}

fun validateBooleanString(string: String?): String? {
    if (string == null) return null
    if (string == "true" || string == "false") return null
    return "invalid-format"
}

fun validateSelect(string: String?, options: String?): String? {
    if (string == null) return null
    if (string.trim() == "") return "invalid-missing"
    if (options != null && options.lines().contains(string)) return null
    return "invalid-format"
}

fun validateAnswer(question: Question?, answerWording: String?): String? {
    if (answerWording == null) return null
    if (question == null) {
        return validateBlockText(answerWording)
    }
    else {
        when (QuestionFormat.valueOf(question.format)) {
            QuestionFormat.SIMPLE    -> return validateText(answerWording)
            QuestionFormat.MULTILINE -> return validateBlockText(answerWording)
            QuestionFormat.DATE      -> return validateLocalDate(answerWording)
            QuestionFormat.TIME      -> return validateLocalTime(answerWording)
            QuestionFormat.DATETIME  -> return validateLocalDateTime(answerWording)
            QuestionFormat.TIMESTAMP -> return validateTimestampString(answerWording)
            QuestionFormat.EMAIL     -> return validateEmail(answerWording)
            QuestionFormat.PHONE     -> return validatePhone(answerWording)
            QuestionFormat.REGEX     -> return validateRegex(answerWording, question.regex)
            QuestionFormat.SELECT    -> return validateSelect(answerWording, question.options)
            QuestionFormat.CHECKBOX  -> return validateBooleanString(answerWording)
        }
    }
}

// Very simple checking for the worst terms only.
// TODO Obvisouly we need a more intelligent (ai?) solution and more i18n.
private val hateSpeech = arrayOf(
    "reggin", "tnuc", "kcuf", "rekcuf", "gnikcuf", "kcufrehtom", "rekcufrehtom", "gnikcufrehtom",
    "ssip", "elohtihs", "daehgar", "toggaf", "cips", "ikap", "rekcuskcoc", "oppyg", "yekip","oobagij",
    "tset_hceeps_etah", "dronf"
)
private val hateSpeechRegex = Regex(hateSpeech.joinToString(prefix = "\\b", separator = "\\b|\\b", postfix = "\\b") { it.reversed() }, RegexOption.IGNORE_CASE)
// TODO tried [[:cntrl:]] here, but didn't work. Not sure this list is exhaustive ... maybe a better way?
// Also had to drop \a and \e because js didn't like them.
private val controlChars = Regex("[\\t\\n\\r\\f]")
fun validateText(text: String?, minLength: Int = 1, maxLength: Int = 500, preventControlCharacters:Boolean = true): String? {
    if (text == null) {
        return null
    }
    val trimmedLength = text.trim().length
    if (trimmedLength == 0) {
        return "invalid-missing"
    }
    if (trimmedLength < minLength) {
        return "invalid-too-short"
    }
    if (text.length > maxLength) {
        return "invalid-too-long"
    }
    if (preventControlCharacters && controlChars.containsMatchIn(text)) {
        return "invalid-problem-character"
    }
    if (hateSpeechRegex.containsMatchIn(text)) {
        return "invalid-problem-word"
    }
    return null
}
fun validateBlockText(text: String?): String?
    = validateText(text = text, minLength = 3, maxLength = 5000, preventControlCharacters = false)

/*
This was in Pincer.kt with no references apart from a unit test.
Server side thing only? More generalised to other millis based maps?
fun validateNewChatId(chatId: String): String? {
    val millis = Regex("C(\\d+)").find(chatId)!!.groupValues.get(1).toLong()
    val now = millisSince()
    if (millis > now + 5*1000L) throw InvalidData("Chat id from the future? $chatId")
    if (millis < now - 60*1000L) throw InvalidData("Chat id to old? $chatId")
}
*/
