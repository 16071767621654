package com.pincer.client

import com.pincer.core.Logger

// Simple pub/sub for the application stack.
// I looked at doing this with mutableSharedFlow<Unit> which is still probably the simplest way.
// Working this way for now as I know the JS side of things is happy with the callbacks.
// But counts as a TODO to make this more Kotlin clever.

class Changes(val log: Logger) {

    private val listeners = mutableListOf<() -> Unit>()

    fun listen(f:() -> Unit) {
        log.i("Adding listener")
        listeners.add(f)
    }

    fun emit() {
        for (listener in listeners) {
            try { 
                listener() 
            }
            catch (t: Throwable) {
                log.w("Problem signalling listener", t)
            }
        }
    }

}
