package com.pincer.core.functions

import com.pincer.core.model.tree.Pincer
import com.pincer.core.model.tree.Participant
import com.pincer.core.model.tree.Question
import com.pincer.core.model.tree.Answer
import com.pincer.core.model.tree.NAME_SCOPE
import com.pincer.core.model.tree.DESCRIPTION_SCOPE
import com.pincer.core.model.tree.CHATS_SCOPE
import com.pincer.core.model.tree.QUESTIONS_SCOPE
import com.pincer.core.model.tree.PARTICIPANTS_SCOPE
import com.pincer.core.model.tree.PARTICIPANTS_RULES_SCOPE
import com.pincer.core.model.tree.PARTICIPANTS_ANSWERS_SCOPE
import com.pincer.core.model.tree.CANDIDATES_SCOPE
import com.pincer.core.model.tree.SUMMARIES_SCOPE
import com.pincer.core.model.tree.PERMISSIONS_SCOPE
import com.pincer.core.InvalidData
import com.pincer.core.TERMS_LAST_CHANGED_AT_MILLIS
import kotlinx.datetime.Clock

fun readablePid(pincer: Pincer): String {
    return "" +
        pincer.pid.substring(0,3) + "-" +
        pincer.pid.substring(3,6) + "-" +
        pincer.pid.substring(6,9) + "-" +
        pincer.pid.substring(9,12)
}

fun millisSince(pincer: Pincer, clock: Clock = Clock.System): Long {
    return clock.now().toEpochMilliseconds() - pincer.createdAt 
}

fun millisBasedId(pincer: Pincer, prefix: Char, uniqueIn: Map<String, Any>, clock: Clock = Clock.System): String {
    val m = millisSince(pincer, clock)
    var i = 0
    while (i < 1000) {
        val id = prefix.plus((m+i).toString().padStart(15, '0'))
        if (uniqueIn[id] == null) return id
        i++
    }
    throw Exception("Can't find unique id")
}

fun newChatId(pincer: Pincer)       = millisBasedId(pincer = pincer, prefix = 'C', uniqueIn = pincer.chats)
fun newQuestionId(pincer: Pincer)   = millisBasedId(pincer = pincer, prefix = 'Q', uniqueIn = pincer.questions)
fun newPermissionId(pincer: Pincer) = millisBasedId(pincer = pincer, prefix = 'M', uniqueIn = pincer.permissions)

fun getRolesForParticipant(pincer: Pincer, principalId: String): String? {
    for (id in pincer.participants.keys) {
        if (id == principalId) {
            return pincer.participants[id]!!.roles
        }
    }
    return null
}


fun checkPermission(pincer: Pincer, roles: String?, scope: String, write: Boolean): Boolean {
    if (roles == null) return false
    for (role in roles.split(",")) {
        for (permission in pincer.permissions.values) {
            if (permission.role == role && permission.scope == scope && (!write || permission.write)) {
                return true
            }
        }
    }
    return false
}

fun trimForParticipant(pincer: Pincer, principalId: String): Pincer {
    if (pincer.pid == principalId) return pincer
    // TODO, avoid some more of these by making them nullable
    val build = Pincer(
        pid             = pincer.pid,
        createdAt       = pincer.createdAt,
        parent          = pincer.parent,
        sid             = pincer.sid,
        sidParent       = pincer.sidParent,
        sidPrincipal    = pincer.sidPrincipal,
        days            = pincer.days,
        daysStart       = pincer.daysStart,
        daysEnd         = pincer.daysEnd,
        zone            = pincer.zone,
        rules           = pincer.rules,
        openRoles       = pincer.openRoles,
        forkRole        = pincer.forkRole,
        isPrincipal     = pincer.isPrincipal,
        candidatesStale = pincer.candidatesStale,
    )
    val roles = getRolesForParticipant(pincer, principalId)
    if (roles == null) return build
    val rolesArray = roles.split(",")
    if (checkPermission(pincer = pincer, roles = roles, scope = NAME_SCOPE, write = false)) {
        build.name = pincer.name
    }
    if (checkPermission(pincer = pincer, roles = roles, scope = DESCRIPTION_SCOPE, write = false)) {
        build.description = pincer.description
    }
    for (chatId in pincer.chats.keys) {
        val chat = pincer.chats[chatId]!!
        if (checkPermission(pincer = pincer, roles = roles, scope = CHATS_SCOPE, write = false)) {
            val chatRoles = chat.roles
            if (chatRoles == null || rolesArray.intersect(chatRoles.split(",")).isNotEmpty()) {
                build.chats[chatId] = chat.duplicate()
            }
        }
    }
    val questionsWrite = checkPermission(pincer = pincer, roles = roles, scope = QUESTIONS_SCOPE, write = true)
    for (questionId in pincer.questions.keys) {
        val question = pincer.questions[questionId]!!
        if (checkPermission(pincer = pincer, roles = roles, scope = QUESTIONS_SCOPE, write = false)) {
            val questionRoles = question.roles
            // write access people see everything, but otherwise when roles are specified we filter
            if (questionsWrite || questionRoles == null || questionRoles == "" || rolesArray.intersect(questionRoles.split(",")).isNotEmpty()) {
                build.questions[questionId] = question.duplicate()
            }
        }
    }
    for (participantId in pincer.participants.keys) {
        val participant = pincer.participants[participantId]!!
        if (participantId == principalId) {
            build.participants[participantId] = participant.duplicate(includeRules = true, includeAnswers = true)
        }
        else {
            if (checkPermission(pincer = pincer, roles = roles, scope = PARTICIPANTS_SCOPE, write = false)) {
                val includeRules = checkPermission(pincer = pincer, roles = roles, scope = PARTICIPANTS_RULES_SCOPE, write = false)
                val includeAnswers = checkPermission(pincer = pincer, roles = roles, scope = PARTICIPANTS_ANSWERS_SCOPE, write = false)
                build.participants[participantId] = participant.duplicate(includeRules = includeRules, includeAnswers = includeAnswers)
            }
        }
    }
    if (checkPermission(pincer = pincer, roles = roles, scope = CANDIDATES_SCOPE, write = false)) {
        for (candidateId in pincer.candidates.keys) {
            build.candidates[candidateId] = pincer.candidates[candidateId]!!.duplicate()
        }
    }
    if (checkPermission(pincer = pincer, roles = roles, scope = SUMMARIES_SCOPE, write = false)) {
        for (summaryId in pincer.summaries.keys) {
            build.summaries[summaryId] = pincer.summaries[summaryId]!!.duplicate()
        }
    }
    val allPermissions = checkPermission(pincer = pincer, roles = roles, scope = PERMISSIONS_SCOPE, write = false)
    for (permissionId in pincer.permissions.keys) {
        val permission = pincer.permissions[permissionId]!!
        if (allPermissions || rolesArray.contains(permission.role)) {
            build.permissions[permissionId] = permission.duplicate()
        }
    }

    return build
}

fun getQuestionId(pincer: Pincer, questionIdOrCode: String): String? {
    for (questionId in pincer.questions.keys) {
        if (questionId == questionIdOrCode || pincer.questions[questionId]!!.code == questionIdOrCode) {
            return questionId
        }
    }
    return null
}

fun getQuestion(pincer: Pincer, questionIdOrCode: String): Question? {
    val questionId = getQuestionId(pincer = pincer, questionIdOrCode = questionIdOrCode)
    if (questionId == null) return null
    return pincer.questions[questionId]
}

fun getAnswerId(pincer: Pincer, questionIdOrCode: String, participant: Participant): String? {
    val questionId: String? = getQuestionId(pincer = pincer, questionIdOrCode = questionIdOrCode)
    if (questionId == null) {
        if (participant.answers[questionIdOrCode] != null) {
            // log.w("Orphan answer?")
            return questionIdOrCode
        }
        throw InvalidData("No question for that id/code")
    }
    if (participant.answers[questionId] != null) return questionId
    return null
}

fun getAnswer(pincer: Pincer, questionIdOrCode: String, participant: Participant): Answer? {
    val id = getAnswerId(pincer = pincer, questionIdOrCode = questionIdOrCode, participant = participant)
    if (id == null) return null
    return participant.answers[id]
}

fun upsertAnswer(pincer: Pincer, questionIdOrCode: String, participant: Participant, wording: String) {
    val questionId = getQuestionId(pincer, questionIdOrCode) ?: throw InvalidData("No question for that id/code")
    if (participant.answers[questionId] == null) {
        participant.answers[questionId] = Answer(wording = wording)
    }
    else {
        participant.answers[questionId]!!.wording = wording
    }
}

fun termsNeeded(lastAcceptedAtMillis: Long?): Boolean {
    if (lastAcceptedAtMillis == null) return true
    return lastAcceptedAtMillis < TERMS_LAST_CHANGED_AT_MILLIS
}