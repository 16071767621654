package com.pincer.core.model.patches

import com.pincer.core.model.tree.Participant
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.js.JsName

@Serializable
data class ParticipantPatch

(

    @JsName("name")    var name    : String? = null,
    @JsName("roles")   var roles   : String? = null,
    @JsName("rules")   var rules   : String? = null,
    @JsName("answers") var answers : MutableMap<String,AnswerPatch?>? = null,

) 

{
    
    fun json(): String {
        return Json.encodeToString(serializer(), this)
    }

    fun asParticipant(): Participant {
        val participant = Participant()
        participant.applyPatch(this)
        return participant
    }

    fun addAnswer(id: String, answerPatch: AnswerPatch?) {
        if (answers == null) {
            answers = mutableMapOf(id to answerPatch)
        } else {
            answers!![id] = answerPatch
        }
    }
    
}