package com.pincer.core.model.tree

import com.pincer.core.model.patches.AnswerPatch
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class Answer 

(

    @JsName("wording") var wording : String,

) 

{

    fun asPatch() = AnswerPatch(
        wording = wording,
    )

    fun applyPatch(patch: AnswerPatch) {
        if (patch.wording != null) wording = patch.wording!!
    }

    fun diff(parent: Answer) =
        AnswerPatch (
            wording = if (wording == parent.wording) null else wording,
        )

    fun duplicate() = Answer(wording = wording)

}
