package com.pincer.core.model.tree

import com.pincer.core.model.patches.SummaryPatch
import kotlinx.serialization.Serializable
import kotlin.js.JsName

/*
This object summarises a Pincer, but will be different for each participant.
Designed to go on settings pincers mostly, but could be used for show children of templates.

Wording will depend on permissions, etc.
e.g. : pincer.description, last chat summary (perhaps with "You: " prepend)
*/

@Serializable
data class Summary 

(

    @JsName("name")           var name: String,
    @JsName("wording")        var wording: String? = null, 
    @JsName("sid")            var sid: Long,
    @JsName("seenStatus")     var seenStatus: Int = 0,
    @JsName("dotNumber")      var dotNumber: Int = 0,

) 

{

    fun asPatch(): SummaryPatch = SummaryPatch(
        name       = name,
        wording    = wording,
        sid        = sid,
        seenStatus = seenStatus,
        dotNumber  = dotNumber,
    )
    
    fun applyPatch(patch: SummaryPatch) {
        if (patch.name       != null) name       = patch.name!!
        if (patch.wording    != null) wording    = patch.wording!!
        if (patch.sid        != null) sid        = patch.sid!!
        if (patch.seenStatus != null) seenStatus = patch.seenStatus!!
        if (patch.dotNumber  != null) dotNumber  = patch.dotNumber!!
    }

    fun diff(ancestor: Summary) = SummaryPatch (
        name       = if (name       == ancestor.name)       null else name,
        wording    = if (wording    == ancestor.wording)    null else wording,
        sid        = if (sid        == ancestor.sid)        null else sid,
        seenStatus = if (seenStatus == ancestor.seenStatus) null else seenStatus,
        dotNumber  = if (dotNumber  == ancestor.dotNumber)  null else dotNumber,
    )

    fun duplicate() = Summary(
        name       = name, 
        wording    = wording, 
        sid        = sid, 
        seenStatus = seenStatus, 
        dotNumber  = dotNumber, 
    )

}
