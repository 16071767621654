package com.pincer.core.model.tree

import com.pincer.core.model.patches.QuestionPatch
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class Question 

(

    @JsName("format")       var format       : String = QuestionFormat.SIMPLE.toString(),
    @JsName("code")         var wording      : String,
    @JsName("wording")      var code         : String,
    @JsName("helpText")     var helpText     : String? = null,
    @JsName("options")      var options      : String? = null,
    @JsName("regex")        var regex        : String? = null,
    @JsName("roles")        var roles        : String? = null,

) 

{

    fun asPatch() = QuestionPatch(
        format       = format,
        code         = code,
        wording      = wording,
        helpText     = helpText,
        options      = options,
        regex        = regex,
        roles        = roles,
    )

    fun applyPatch(patch: QuestionPatch) {
        if (patch.format       != null) format       = patch.format!!
        if (patch.code         != null) code         = patch.code!!
        if (patch.wording      != null) wording      = patch.wording!!
        if (patch.helpText     != null) helpText     = patch.helpText!!
        if (patch.options      != null) options      = patch.options!!
        if (patch.regex        != null) regex        = patch.regex!!
        if (patch.roles        != null) roles        = patch.roles!!
    }

    fun diff(parent: Question) =
        QuestionPatch (
            format   = if (format    == parent.format)   null else format,
            code     = if (code      == parent.code)     null else code,
            wording  = if (wording   == parent.wording)  null else wording,
            helpText = if (helpText  == parent.helpText) null else helpText,
            options  = if (options   == parent.options)  null else options,
            regex    = if (regex     == parent.regex)    null else regex,
            roles    = if (roles     == parent.roles)    null else roles,
        )

    fun duplicate() = Question(format = format, code = code, wording = wording, helpText = helpText, options = options, regex = regex, roles = roles)

    fun hasHelpText(): Boolean = helpText != null && helpText!!.trim().length > 0

    fun formatLikeSimple(): Boolean {
        if (format == QuestionFormat.MULTILINE.toString()) return false;
        if (format == QuestionFormat.SELECT.toString())    return false;
        if (format == QuestionFormat.CHECKBOX.toString())  return false;
        return true
    }

    /*
    Notes ...
    For format:
        simple : one line text
        date / time / datetime : all local
        timestamp : unix number
        email / phone : simple, with managed validation
        validated : simple, with a supporting regex field
        multiline : block of text, multiple paragraphs
        select : multichoice
        multiselect : checkboxes

    For the selects, a question could have its own map of answers, which would give the id strings to store 
    in a participant's answers, safe to comma delimit.

    Also, if wording is blank the convention is that the id can be used for a i18n lookup.
    */

}

enum class QuestionFormat { 
    SIMPLE, DATE, TIME, DATETIME, TIMESTAMP, EMAIL, PHONE, REGEX, MULTILINE, SELECT, CHECKBOX
}
