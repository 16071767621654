package com.pincer.core

import co.touchlab.kermit.SimpleFormatter
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
private data class LogLine(val lvl: String, val pid: String, val pri: String, val msg: String, val err: String? = null) {

    fun errOrEmpty(): String {
        if (err == null) return ""
        return " " + err
    }

    fun default(): String {
        return "$lvl: ($pid.$pri) $msg ${errOrEmpty()}".trim()
    }

    fun noTags(): String {
        return "$lvl: $msg ${errOrEmpty()}".trim()
    }
    
    fun json(): String {
        return Json.encodeToString(serializer(), this)
    }

} 

class Logger(
    val pid: String = "-".repeat(12),
    val principalId: String = "-".repeat(16),
    val format: String = "default",
    val oneLine: Boolean = true,
    val ansi: Boolean = false,
) {

    fun logger(withPid:String = pid, withPrincipalId: String = principalId): Logger 
        = Logger(pid = withPid, principalId = withPrincipalId, format = format, oneLine = oneLine, ansi = ansi)

    // We bypass weak kermit layout stuff here, just log the message ...
    private val kermit = co.touchlab.kermit.Logger(config = loggerConfigInit(platformLogWriter(SimpleFormatter)))
    // TODO - is kermit even doing much for us anymore ... could just add our own multiplatform code instead?

    private val ANSI_RESET  = "\u001B[0m"
    private val ANSI_RED    = "\u001B[31m"
    private val ANSI_GREEN  = "\u001B[32m"
    private val ANSI_YELLOW = "\u001B[33m"
    private val ANSI_BLUE   = "\u001B[34m"

    private fun doFormat(lvl: String, message: String, throwable: Throwable?): String {
        val logLine = LogLine(lvl, pid, principalId, message, throwable?.stackTraceToString())
        var build: String
        when(format) {
            "default" -> build = logLine.default()
            "noTags"  -> build = logLine.noTags()
            "json"    -> build = logLine.json()
            else      -> throw Exception("Bad format $format")
        }
        if (ansi) {
            if (lvl == "WARN") build = ANSI_YELLOW + build + ANSI_RESET
            if (lvl == "ERROR") build = ANSI_RED + build + ANSI_RESET
            if (lvl == "HIGH") build =  ANSI_BLUE + build + ANSI_RESET
            if (principalId.startsWith("roz_")) build = ANSI_GREEN + build + ANSI_GREEN
        }
        if (oneLine) {
            build = build.replace("\n", "\\n")
        }
        return build
    }

    fun i(message: String, throwable: Throwable? = null) = kermit.i(doFormat("INFO",  message, throwable), throwable)
    fun w(message: String, throwable: Throwable? = null) = kermit.w(doFormat("WARN",  message, throwable), throwable)
    fun e(message: String, throwable: Throwable? = null) = kermit.e(doFormat("ERROR", message, throwable), throwable)
    fun h(message: String, throwable: Throwable? = null) = kermit.i(doFormat("HIGH",  message, throwable), throwable)

}