package com.pincer.core.model.tree

import com.pincer.core.model.patches.PermissionPatch
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class Permission 

(

    @JsName("role")        var role        : String,
    @JsName("scope")       var scope       : String,
    @JsName("write")       var write       : Boolean = false,

) 

{

    fun asPatch() = PermissionPatch(
        role        = role,
        scope       = scope,
        write       = write,
    )

    fun applyPatch(patch: PermissionPatch) {
        if (patch.role        != null) role        = patch.role!!
        if (patch.scope       != null) scope       = patch.scope!!
        if (patch.write       != null) write       = patch.write!!
    }

    fun diff(parent: Permission) =
        PermissionPatch (
            role        = if (role        == parent.role)        null else role,
            scope       = if (scope       == parent.scope)       null else scope,
            write       = if (write       == parent.write)       null else write,
        )

    fun id() = "P${hashCode().toLong()+Int.MAX_VALUE}"

    fun duplicate() = Permission(role = role, scope = scope, write = write)

}

val NAME_SCOPE                 = "pincer.name"
val DESCRIPTION_SCOPE          = "pincer.description"
val CHATS_SCOPE                = "pincer.chats"
val CANDIDATES_SCOPE           = "pincer.candidates"
val SUMMARIES_SCOPE            = "pincer.summaries"
val QUESTIONS_SCOPE            = "pincer.questions"
val PARTICIPANTS_SCOPE         = "pincer.participants"
val PARTICIPANTS_RULES_SCOPE   = "pincer.participants.rules"
val PARTICIPANTS_ROLES_SCOPE   = "pincer.participants.roles"
val PARTICIPANTS_ANSWERS_SCOPE = "pincer.participants.answers"
val PERMISSIONS_SCOPE          = "pincer.permissions"
val RULES_SCOPE                = "pincer.rules"

