package com.pincer.client

import com.pincer.core.Logger
import com.pincer.core.model.Device
import com.pincer.core.model.tree.Pincer
import com.pincer.core.model.patches.PincerPatch
import com.pincer.core.SuspendingContract
import kotlinx.datetime.Clock

class ClientSession(
        val server: String, 
        val deviceId: String, 
        val deviceSecret: String, 
        val principalId: String,
        val contract: SuspendingContract = ClientContract(server),
    ) {

    companion object {

        suspend fun newDevice(server: String = DEFAULT_SERVER_URL, log: Logger): ClientSession {
            log.i("Client auth set up against $server ...")
            val device = ClientContract(server).registerDevice()
            log.i("Registerd ${device.id} ${device.principalId}")
            return ClientSession(server, device.id, device.secret, device.principalId)
        } 

        // {"alg":"HS256","typ":"JWT"}
        val header = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" 

        fun jwt(iss: String, sub: String, secret: String): String {
            val exp = (Clock.System.now().toEpochMilliseconds()/1000) + (60*5)
            val payload = mpBase64UrlEncode("""{"iss":"$iss","sub":"$sub","exp":$exp}""")
            val toSign = "$header.$payload"
            val signature = mpHmacSha256(toSign, secret)
            return "$header.$payload.$signature"
        }

    }

    suspend fun getJwt(): String {
        return jwt(iss = deviceId, sub = principalId, secret = deviceSecret)
    }

    suspend fun get(pid: String): Pincer 
        = contract.get(getJwt(), pid)

    suspend fun getAt(pid: String, sid: Long): Pincer 
        = contract.getAt(getJwt(), pid, sid)

    suspend fun getSince(pid: String, sid: Long): PincerPatch 
        = contract.getSince(getJwt(), pid, sid)

    suspend fun fork(pid: String, patch: PincerPatch?): String 
        = contract.fork(getJwt(), pid, patch)

    suspend fun patch(pid: String, patch: PincerPatch): Long 
        = contract.patch(getJwt(), pid, patch)

    suspend fun registerDevice(): Device
        = contract.registerDevice()

    suspend fun webSocketUrl(pid: String): String
        = contract.webSocketUrl(getJwt(), pid)

}