package com.pincer.core

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
open class PincerException(
    override val message: String = "Something went wrong on our side.",
): Exception(message) {
    open val code: Int = 500
    open val type: String = "PincerException"
}

open class ProblemWithResponse(
    override val message: String = "Something went wrong on our side. Its not you, its me.",
): PincerException(message) {
    override val code: Int = 500
    override val type: String = "ProblemWithResponse"
}

class TryLater(
    override val message: String = "Sorry, that item isn't ready for you just now. Please try back in a little while.",
): ProblemWithResponse(message) {
    override val code: Int = 503
    override val type: String = "TryLater"
}

open class ProblemWithRequest(
    override val message: String = "There was a problem with your request. Sorry we can't share more details.",
): PincerException(message) {
    override val code: Int = 400
    override val type: String = "ProblemWithRequest"
}

class YouNeedToLogIn(
    override val message: String = "You need to login.",
): ProblemWithRequest(message) {
    override val code: Int = 401
    override val type: String = "YouNeedToLogIn"
}

class YouAreNotAllowed(
    override val message: String = "Sorry, you don't have permissions to interact with that item.",
): ProblemWithRequest(message) {
    override val code: Int = 403
    override val type: String = "YouAreNotAllowed"
}

class DoesNotExist( 
    override val message: String = "Sorry, the requested item does not exist.",
): ProblemWithRequest(message) {
    override val code: Int = 404
    override val type: String = "DoesNotExist"
}

class InvalidData(
    override val message: String = "Sorry, it looks like there's something wrong with the data you sent.",
) : ProblemWithRequest(message) {
    override val code: Int = 400
    override val type: String = "InvalidData"
}

// TODO Polymorphism in Kotlin Serializations is discussed here ...
// https://github.com/Kotlin/kotlinx.serialization/blob/master/docs/polymorphism.md
// I haven't got this working according to their scheme yet though and out 'type' field and the block
// below is the homebrew version of what should be the automatic descriminator field. We can't use their
// standard sealed class thing I think b/c our root class extends Exception. 
fun parse(json: String): PincerException {
    val e = Json.decodeFromString<PincerException>(PincerException.serializer(), json)
    if (e.type == "ProblemWithResponse") return ProblemWithResponse(e.message)
    if (e.type == "TryLater")            return TryLater(e.message)
    if (e.type == "YouNeedToLogIn")      return YouNeedToLogIn(e.message)
    if (e.type == "YouAreNotAllowed")    return YouAreNotAllowed(e.message)
    if (e.type == "DoesNotExist")        return DoesNotExist(e.message)
    if (e.type == "InvalidData")         return InvalidData(e.message)
    return e
}
