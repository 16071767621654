package com.pincer.core.model.tree

import com.pincer.core.model.patches.ParticipantPatch
import kotlinx.serialization.Serializable
import kotlin.js.JsName

@Serializable
data class Participant 

(

    @JsName("name")      var name    : String? = null,
    @JsName("roles")     var roles   : String = "",
    @JsName("rules")     var rules   : String = "true",
    @JsName("answers")   var answers : MutableMap<String,Answer> = mutableMapOf<String,Answer>(),

) 

{

    fun asPatch(): ParticipantPatch = ParticipantPatch(
        name    = name,
        roles   = roles,
        rules   = rules,
    )
    
    fun applyPatch(patch: ParticipantPatch) {
        if (patch.name    != null) name    = patch.name!!
        if (patch.roles   != null) roles   = patch.roles!!
        if (patch.rules   != null) rules   = patch.rules!!
        patch.answers?.forEach({ entry -> 
            val theirAnswer = entry.value
            val ourAnswer = answers[entry.key]
            if (ourAnswer == null && theirAnswer == null) {
                // no action
            }
            else if (ourAnswer != null && theirAnswer == null) {
                answers.remove(entry.key)
            }
            else if (ourAnswer == null && theirAnswer != null) {
                answers[entry.key] = theirAnswer.asAnswer()
            }
            else {
                ourAnswer!!.applyPatch(theirAnswer!!)
            }
        })
    }

    fun diff(ancestor: Participant): ParticipantPatch {
        val patch = ParticipantPatch(
            name    = if (name    == ancestor.name)    null else name,
            roles   = if (roles   == ancestor.roles)   null else roles,
            rules   = if (rules   == ancestor.rules)   null else rules,
        )
        for (id in answers.keys) {
            val ourAnswer = answers[id]!!
            var theirAnswer = ancestor.answers[id]
            if (theirAnswer == null) {
                patch.addAnswer(id, ourAnswer.asPatch())
            } else if (ourAnswer != theirAnswer) {
                patch.addAnswer(id, ourAnswer.diff(theirAnswer))
            }
        }
        for (id in ancestor.answers.keys) {
            if (answers[id] == null) {
                patch.addAnswer(id, null)
            }
        }
        return patch
    }

    fun duplicate(includeRules:Boolean, includeAnswers: Boolean): Participant {
        val useRules = if (includeRules) rules else "REDACTED"
        val build = Participant(name = name, roles = roles, rules = useRules)
        if (includeAnswers) {
            answers.forEach({ entry -> 
                build.answers[entry.key] = entry.value.duplicate()
            })
        }
        return build
    }

}
